import { useEffect, useState } from "react";

const useClickOutside = ({
  ref = false,
  requireShift = false,
  focusState,
  onSubmit,
  onBlur,
}) => {
  const [shiftPressed, setShiftPressed] = useState();
  // Call the callback when an outside click is detected
  const handleClickOutside = (event) => {
    if (
      ref &&
      ref.current &&
      !ref.current.contains(event.target) &&
      focusState
    ) {
      onSubmit();
    }
  };

  // Call the callback when the Enter or Escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Shift") {
      setShiftPressed(true);
    }

    if (event.key === "Enter") {
      if (!requireShift || (requireShift && !shiftPressed)) onSubmit();
    }
    if (event.key === "Escape") {
      onBlur();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Shift") {
      setShiftPressed(false);
    }
  };

  useEffect(() => {
    if (!focusState) return;

    // Add event listener to the document for outside click and keydown
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
    };
  }, [ref, onSubmit, onBlur, focusState]);

  return ref;
};

export default useClickOutside;
