import React, { useState, useRef } from "react";
import "./textarea.css";
import { Pill } from "../pill/Pill";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

const Textarea = ({
  onChange,
  value,
  placeholder,
  onCharacterChange,
  onNavigate,
  isLoading,
}) => {
  const [tempValue, setTempValue] = useState(value);
  const timeoutRef = useRef(null); // Use useRef to hold the timeout ID

  const handleUpdate = (value) => {
    onCharacterChange(value);
    setTempValue(value.trim());
    // Clear any existing timeout to prevent it from running
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // Store the new timeout ID in timeoutRef.current
    timeoutRef.current = setTimeout(async () => {
      console.log("Trgiger");
      await onChange(value); // Use the latest value instead of tempValue to ensure it's up-to-date
    }, 500);
  };

  const ref = useRef();

  return (
    <div className="textarea-container">
      <textarea
        className="textarea-element"
        onChange={(event) => handleUpdate(event.target.value)}
        placeholder={placeholder}
        ref={ref}
      >
        {tempValue}
      </textarea>
    </div>
  );
};

export { Textarea };
