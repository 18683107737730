import React, { useEffect } from "react";
import { Stack } from "../../../components/common";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNodes,
  resetChangedNodeId,
  nodeStatus as getNodeStatus,
} from "../nodeSlice";

import MeetingCard from "./MeetingCard";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

const MeetingsPageDefault = ({ date }) => {
  const meetings = useSelector((state) => state.node.nodes).filter(
    (node) =>
      node.type === "meeting" &&
      node.status !== "archived" &&
      dayjs(node.dateTodo).isSame(date, "day")
  );
  const dispatch = useDispatch();
  const nodeStatus = useSelector(getNodeStatus);

  useEffect(() => {
    return () => {
      dispatch(resetChangedNodeId());
    };
  }, [dispatch]);
  return (
    <>
      <Stack
        emptyState={{
          message: "No meetings today",
          children: "Change the date filter to see meetings from other dates",
        }}
      >
        {meetings
          .filter((meeting) => {
            const dayValidation = dayjs(meeting.dateTodo).isSameOrBefore(
              dayjs(),
              "day"
            );

            return dayValidation;
          })
          .sort((a, b) => {
            if (a.dateTodo > b.dateTodo) return -1;
            return 1;
          })
          .map((meeting) => {
            return (
              <MeetingCard
                key={meeting._id}
                node={meeting}
                nodeStatus={nodeStatus}
                urgent={dayjs(meeting.dateTodo).isBefore(dayjs(), "day")}
              />
            );
          })}
      </Stack>
    </>
  );
};

export default MeetingsPageDefault;
