import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "./Dropdown";
import { components } from "react-select";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import useProjectColors from "../../../hooks/useProjectColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./projects.css";
import useScreenSize from "../../../hooks/useScreenSize";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

const Project = (props) => {
  return (
    <components.Option {...props}>
      <div className="project-option">
        <div className="project-option-label">{props.data.label}</div>
        {props.isSelected && (
          <FontAwesomeIcon icon={faCheck} className="project-option-icon" />
        )}
      </div>
    </components.Option>
  );
};

const Projects = ({ value, onChange = false, responsive = true }) => {
  const stateOptions = useSelector((state) => state.project.projects);

  const options = useMemo(
    () =>
      Object.values(stateOptions)
        .map(({ title, color, _id }) => ({
          label: title,
          value: _id,
          color,
        }))
        .sort((a, b) => {
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          return 0;
        }),
    [stateOptions]
  );

  const fontColors = useProjectColors();
  const borderColors = useProjectColors(0.3);
  const bgColors = useProjectColors(0.7);
  const screenSize = useScreenSize();

  if (screenSize < 3 && responsive) {
    const project = options.find((option) => option.value === value);
    const color = bgColors[project ? project.color : "grey"];
    return <FontAwesomeIcon icon={faCircle} style={{ color }} />;
  }

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: 13,
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: 4,
      backgroundColor: "transparent",
    }),
    option: (provided, state) => {
      const value = state.data;
      const borderColor = value ? borderColors[value.color] : "#dbdbdb";
      const fontColor = value ? fontColors[value.color] : "#dbdbdb";

      return {
        ...provided,
        fontSize: 13,
        marginTop: 5,
        borderRadius: 7,
        fontFamily: "Lato, sans-serif",
        border: `1px solid ${borderColor}`,
        color: fontColor,
        cursor: "pointer",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        ...(!state.isSelected && {
          ":hover": {
            border: `1px solid ${fontColor}`,
            backgroundColor: "transparent",
            color: fontColor,
            transition: "border 0.3s, color 0.3s",
          },
        }),
        ":focus": {
          border: `1px solid ${borderColor}`,
          backgroundColor: "transparent",
        },
        ...(state.isFocused && {
          backgroundColor: "transparent",
          borderColor: borderColor,
          color: fontColor,
        }),
        ...(state.isSelected && {
          backgroundColor: "#fff",
          borderColor: borderColor,
          color: fontColor,
          fontWeight: 500,
        }),
      };
    },
    indicatorSeparator: (provided, state) => ({
      display: "none",
    }),
    singleValue: (provided, state) => {
      const value = state.getValue()[0];
      return {
        ...provided,
        color: value ? fontColors[value.color] : "#dbdbdb",
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        maxWidth: 250,
      };
    },
    dropdownIndicator: () => ({
      display: "none",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),
    control: (provided, state) => {
      const value = state.getValue()[0];
      const borderColor = value ? borderColors[value.color] : "#dbdbdb";

      return {
        ...provided,
        fontFamily: "Lato, sans-serif",
        outline: "none",
        boxShadow: "none",
        borderRadius: 13,
        border: "0px",
        color: "#939393",
        cursor: "pointer",
        fontSize: 13,
        border: `1px solid ${borderColor}`,
        backgroundColor: "#fff",
        borderRadius: 5,
      };
    },
    valueContainer: (provided, state) => {
      const value = state.getValue()[0];
      return {
        ...provided,
        borderRadius: 7,
        margin: "0px 0px",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.3)",
        width: 150,
        maxWidth: 250,
        backgroundColor: "rgba(255,255,255,0.5)",
      };
    },
  };

  return (
    <Dropdown
      components={{
        Option: Project,
      }}
      name="projects"
      options={[
        { label: "No project", value: null, color: "grey" },
        ...options,
      ]}
      style={customStyles}
      placeholder={"No project"}
      onChange={onChange}
      value={
        value && Object.values(options).find((option) => option.value === value)
      }
    />
  );
};

export { Projects };
