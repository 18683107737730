import { useState, useEffect } from "react";

function useTimeToSeconds(timeStr = "00:00") {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timeParts = timeStr.split(":");
    const hoursInSeconds = parseInt(timeParts[0]) * 3600;
    const minutesInSeconds = parseInt(timeParts[1]) * 60;
    const totalSeconds = hoursInSeconds + minutesInSeconds;

    setSeconds(totalSeconds);
  }, [timeStr]);

  return seconds;
}

export default useTimeToSeconds;
