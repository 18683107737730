import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { selectNodeIndex } from "../node/nodeSlice";
import axios from "axios";
import dayjs from "dayjs";

const initialState = {
  status: "idle",
  user: {},
  error: null,
};

export const login = createAsyncThunk("/api/login", async () => {
  const { data } = await axios.get("/api/login");
  return data;
});

export const getUser = createAsyncThunk("/api/get_user", async () => {
  const { data } = await axios.get("/api/get_user");
  return data;
});

export const logout = createAsyncThunk("/api/logout", async () => {
  const { data } = await axios.get("/api/logout");
  return data;
});

export const getTimer = createAsyncThunk(
  "/api/user/get_timer",
  async (_, thunkAPI) => {
    const currentTimer = thunkAPI.getState().user.user.timer;

    const { data } = await axios.post("/api/user/get_timer", {
      currentTimer: {
        ...currentTimer,
        startTime: dayjs(currentTimer.startTime),
      },
    });

    return data;
  }
);

export const setTimer = createAsyncThunk(
  "/api/user/set_timer",
  async ({ nodeId, startTime, action, duration }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch("/api/user/set_timer", {
        nodeId,
        startTime,
        duration,
        action,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setError: (state) => {
      state.status = "failed";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = "success";
    });
    builder.addCase(getUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getTimer.fulfilled, (state, action) => {
      state.status = "success";
      state.user.timer = action.payload.timer;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.status = "idle";
      state.user = {};
    });
    builder.addCase(setTimer.fulfilled, (state, action) => {
      state.user.timer = action.payload.timer;
      state.status = "success";
    });
  },
});

export const { setError } = userSlice.actions;
export const userStatus = (state) => state.user.status;
export const selectTimer = (state) =>
  state.user.user.timer || { nodeId: false, startTime: false };

export default userSlice.reducer;
