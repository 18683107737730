import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./menuitem.css";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({ icon, text, link, onClick = () => {}, isDefault }) => {
  const location = useLocation();
  const selected =
    location.pathname.includes(link) ||
    (isDefault && location.pathname === "/");
  return (
    <Link
      to={link}
      className={`menu-item ${selected && "menu-item-selected"}`}
      data-tooltip={text}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className="menu-item-icon" />
      <div className="menu-item-text">
        <span>{text}</span>
      </div>
    </Link>
  );
};

export default MenuItem;
