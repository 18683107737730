import React, { useState, useEffect } from "react";
import {
  NewPage,
  Card,
  Stack,
  TextInput,
  Button,
  Pill,
  Colors,
} from "../../components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircle as faSolidCircle } from "@fortawesome/pro-solid-svg-icons";
import "./projectnew.css";
import axios from "axios";
import {
  faTag,
  faSpinnerThird,
  faCaretCircleRight,
  faSparkles,
  faTimes,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addProject, projectStatus } from "./projectSlice";
import { useNavigate } from "react-router-dom";
import useKeyboardActions from "../../hooks/useKeyboardActions";

const ProjectNew = () => {
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("blue");
  const [keywords, setKeywords] = useState([]);
  const loading = useSelector((state) => state.project.status) === "loading";
  const [error, setError] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    await dispatch(
      addProject({ title, keywords: keywords.map(({ value }) => value), color })
    );
    navigate("/projects");
  };

  const wordBlacklist = [
    "task",
    "complete",
    "deadline",
    "urgent",
    "priority",
    "schedule",
    "assign",
    "due",
    "progress",
    "update",
    "finish",
    "start",
    "organize",
    "plan",
    "review",
    "team",
    "goal",
    "project",
    "collaborate",
    "checklist",
    "follow-up",
    "meeting",
    "reminder",
    "report",
    "status",
    "manage",
    "resource",
    "deliverable",
    "outcome",
    "milestone",
    "the",
    "of",
    "and",
    "to",
    "in",
    "a",
    "is",
    "that",
    "for",
    "it",
    "as",
    "was",
    "with",
    "be",
    "by",
    "on",
    "not",
    "he",
    "i",
    "this",
    "are",
    "or",
    "his",
    "from",
    "at",
    "which",
    "but",
    "have",
    "an",
    "had",
    "they",
    "you",
    "were",
    "their",
    "one",
    "all",
    "we",
    "can",
    "her",
    "has",
    "there",
    "been",
    "if",
    "more",
    "when",
    "will",
    "no",
    "out",
    "do",
    "so",
    "up",
    "said",
    "what",
    "its",
    "about",
    "into",
    "than",
    "them",
    "can",
    "only",
    "other",
    "new",
    "some",
    "could",
    "time",
    "these",
    "two",
    "may",
    "then",
    "see",
    "use",
    "like",
    "how",
    "because",
    "any",
    "good",
    "each",
    "make",
    "well",
    "our",
    "also",
    "very",
    "your",
    "its",
    "even",
    "most",
    "must",
    "over",
    "many",
    "after",
    "before",
    "down",
    "such",
    "through",
    "get",
    "where",
    "back",
    "much",
    "go",
    "me",
  ];

  const generateKeywords = (title) => {
    const words = title.split(" ");
    const acronym = words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    setKeywords(
      [...words, acronym].map((word) => {
        if ([...word].length <= 1 || wordBlacklist.includes(word.toLowerCase()))
          return {};
        return { value: word, active: true };
      })
    );
  };

  const handleRemoveKeyword = (value) => {
    setKeywords(keywords.filter((object) => object.value !== value));
  };

  return (
    <NewPage description="Add a new project">
      <Stack>
        <Card hover={false}>
          <TextInput
            type="inline"
            disabled={loading}
            value={title}
            onChange={(text) => {
              setTitle(text);
              generateKeywords(text);
            }}
            input
            placeholder="Type the name of your new project here..."
          />
          <Button
            onClick={handleSubmit}
            disabled={loading || title.trim() === ""}
            icon={faPlus}
          >
            Create Project
          </Button>
        </Card>
        <Card hover={false}>
          <Colors value={color} onChange={setColor} />
          <div>
            <div className="projectnew-keywords">
              {loading ? (
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              ) : keywords.find((keyword) => keyword.active) ? (
                keywords.map((keyword) => {
                  if (!keyword.active) return;
                  return (
                    <Pill
                      icon={faTag}
                      maxWidth={70}
                      onClick={() => {
                        handleRemoveKeyword(keyword.value);
                      }}
                      hoverIcon={faTimes}
                    >
                      {keyword.value}
                    </Pill>
                  );
                })
              ) : error ? (
                error
              ) : (
                ""
              )}
            </div>
          </div>
        </Card>
      </Stack>
    </NewPage>
  );
};

export default ProjectNew;
