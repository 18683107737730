import React from "react";
import "./card.css";

const Card = ({
  style,
  children,
  onClick = () => {},
  hover = true,
  className,
  loading = false,
  changed = false,
  recording = false,
  selected,
}) => {
  return (
    <div
      className={`card-container ${changed && "card-container-changed"} ${
        hover && "card-container-hover"
      } ${loading && "card-container-loading"} ${className} ${
        recording && "card-container-recording"
      } ${selected && "card-active"}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export { Card };
