import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datedropdown.css";
import { faCalendarAlt, faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"; // Adjust the import path as needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const DateDropdown = ({
  showIcon = true,
  startDate = new Date(),
  changeDate,
  className,
  showCaret = true,
  dateFormat,
  label = null,
  icon = faCalendarAlt,
}) => {
  const smallScreen = useIsSmallScreen();

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="datepicker-custominput"
      onClick={() => {
        onClick();
      }}
      ref={ref}
    >
      {label ? <div className="datepicker-label">{label}</div> : ""}
      {showIcon ? <FontAwesomeIcon icon={icon} /> : ""}
      <div className="datepicker-value">{value}</div>{" "}
      {showCaret ? <FontAwesomeIcon icon={faChevronDown} /> : ""}
    </button>
  ));

  return (
    <div
      className={`datepicker-container ${className}`}
      onClick={(event) => event.preventDefault()}
    >
      <DatePicker
        selected={startDate}
        preventOpenOnFocus
        onChange={(date) => {
          changeDate(date);
        }}
        dateFormat={
          dateFormat ? dateFormat : smallScreen ? "E d MMM" : "EEEE d MMMM yyyy"
        }
        customInput={<CustomInput />}
      />
    </div>
  );
};

export { DateDropdown };
