import React, { useRef, useState } from "react";
import {
  Drawer,
  NodeSection,
  Node,
  DateDropdown,
  TextInput,
  Projects,
  Button,
  Pill,
  Dropdown,
  Textarea,
  Card,
} from "../../../components/common";
import { useNavigate } from "react-router-dom";
import { nodeStatus, selectNode, updateNode } from "../nodeSlice";
import { useDispatch, useSelector } from "react-redux";
import useFormatSeconds from "../../../hooks/useFormatSeconds";
import dayjs from "dayjs";
import { selectTimer, setTimer } from "../../user/userSlice";
import _ from "lodash";
import {
  faCheckCircle,
  faPlayCircle,
  faPlayPause,
  faSpinnerThird,
  faCheck,
  faVideo,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import useProjectColors from "../../../hooks/useProjectColors";
import "./taskdrawer.css";
import { addNode } from "../nodeSlice";
import useCtrlEnter from "../../../hooks/useCtrlEnter";
import ContentEditable from "react-contenteditable";

const NotesDrawerNodes = ({ task }) => {
  const dispatch = useDispatch();

  const [taskName, setTaskName] = useState();

  const handleCharacterChange = (notes) => {
    const regex = /^\*.+$(?![\r\n])/gm;
    const matches = notes.match(regex);
    if (matches && matches.length) {
      return setTaskName(matches[matches.length - 1].replace("*", "").trim());
    }
    return setTaskName("");
  };

  const handleUpdate = (notes) => {
    dispatch(updateNode({ _id: task._id, notes, noToast: true }));
  };

  const handleAddNode = async () => {
    if (!taskName) return;
    await dispatch(
      addNode({
        title: taskName,
        type: "task",
        projectId: task.projectId,
        status: "active",
      })
    );
    setTaskName("");
  };
  const ref = useRef();

  const enter = useCtrlEnter({ ref, onSubmit: handleAddNode });
  const { addNodeLoading } = useSelector(nodeStatus);

  return (
    <div
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
      ref={ref}
    >
      <Textarea
        isLoading={task.loading && task.loading.includes("notes")}
        value={task.notes}
        onChange={handleUpdate}
        onCharacterChange={handleCharacterChange}
        placeholder={"Start writing your notes here..."}
      />

      <Card
        style={{
          borderLeft: 0,
          borderRight: 0,
          flexDirection: "column",
          justifycontent: "flex-start",
        }}
        hover={false}
        onMouseOver
      >
        <div style={{ width: "100%", display: "flex", gap: 10 }}>
          <TextInput
            placeholder="Start a line with * to create a task"
            type="inline"
            input
            style={{ flex: 1 }}
            value={taskName}
            onChange={setTaskName}
            ref={ref}
          />
          <Button
            disabled={!taskName || addNodeLoading}
            icon={addNodeLoading ? faSpinnerThird : faPlus}
            onClick={handleAddNode}
          >
            Task
          </Button>
        </div>
      </Card>
    </div>
  );
};

const SettingsDrawerNodes = ({ task }) => {
  const dispatch = useDispatch();

  const [tempNarrative, setTempNarrative] = useState(
    task.narrative || task.title
  );

  const handleUpdate = (params) => {
    dispatch(updateNode({ _id: task._id, ...params }));
  };

  const timer = useSelector(selectTimer);
  const isActiveNode = timer.nodeId === task._id && timer.active;

  const handleTimer = () => {
    dispatch(
      setTimer({
        nodeId: task._id,
        startTime: new Date(),
        active: !isActiveNode,
        action: isActiveNode ? "stop" : "start",
      })
    );
  };

  const navigate = useNavigate();
  const loading = task.loading || [];

  const now = dayjs().format("YYYY-MM-DD");

  const total = useFormatSeconds((task.duration && task.duration.total) || 0);
  const today = useFormatSeconds((task.duration && task.duration[now]) || 0);
  const statusOptions = [
    { value: "active", label: "Not complete" },
    { value: "complete", label: "Complete" },
  ];
  const colors = useProjectColors();
  const typeOptions = [
    { value: "task", label: "Task", icon: faCheck },
    { value: "meeting", label: "Meeting" },
  ];
  return (
    <div className="taskdrawer">
      <NodeSection>
        <Node title="Type">
          <Dropdown
            options={typeOptions}
            onChange={(option) => handleUpdate({ type: option.value })}
            value={typeOptions.find((option) => option.value === task.type)}
          />{" "}
        </Node>
      </NodeSection>
      <NodeSection>
        <Node title="Project">
          <Projects
            value={task.projectId}
            responsive={false}
            onChange={({ value }) => handleUpdate({ projectId: value })}
          />
        </Node>

        <Node title={`Date`}>
          {loading.includes("dateTodo") ? (
            <Pill icon={faSpinnerThird} />
          ) : (
            <DateDropdown
              startDate={new Date(task.dateTodo)}
              changeDate={(dateTodo) => handleUpdate({ dateTodo })}
            />
          )}
        </Node>

        <Node title="Status">
          {loading.includes("status") ? (
            <Pill icon={faSpinnerThird} />
          ) : (
            <Dropdown
              name="status"
              options={statusOptions}
              onChange={(option) =>
                handleUpdate({
                  status: option.value,
                })
              }
              value={statusOptions.find(
                (option) => option.value === task.status
              )}
            />
          )}
        </Node>
      </NodeSection>
      <NodeSection>
        <Node title="Timer status">
          <Pill
            iconColor={isActiveNode ? colors.red : false}
            type="secondary"
            showText
            icon={
              loading.includes("timer")
                ? faSpinnerThird
                : isActiveNode
                ? faCircle
                : faPlayCircle
            }
            onClick={handleTimer}
          >
            {isActiveNode ? "Timer started" : "Timer not started"}
          </Pill>
        </Node>
        <Node title="Time narrative">
          <TextInput
            value={tempNarrative}
            multiLine
            onChange={(value) => {
              setTempNarrative(value);
            }}
            onReset={() => setTempNarrative(task.narrative)}
            onSubmit={() => {
              if (tempNarrative !== task.narrative)
                handleUpdate({ narrative: tempNarrative });
            }}
          />
        </Node>
        <Node title="Time today">{today || "No time recorded"}</Node>

        <Node title="Total time">{total || "No time recorded"}</Node>
      </NodeSection>{" "}
      <NodeSection>
        <Node extend>
          <Button
            type="danger"
            onClick={() => {
              handleUpdate({ status: "archived" });
              navigate("/tasks");
            }}
          >
            Delete
          </Button>
        </Node>
      </NodeSection>
    </div>
  );
};

const ActivityDrawer = ({ taskId, defaultDrawerNodes = "settings" }) => {
  const [drawerNodes, setDrawerNodes] = useState(defaultDrawerNodes);
  const navigate = useNavigate();
  const task = useSelector((state) => selectNode(state, taskId));
  const dispatch = useDispatch();

  if (!task) return;

  return (
    <Drawer
      visible={!!taskId}
      _id={taskId}
      title={task ? task.title : ""}
      onChangeTitle={(title) => dispatch(updateNode({ _id: taskId, title }))}
      onClose={() => navigate(-1)}
      navigation={[
        {
          text: "Properties",
          onClick: () => setDrawerNodes("settings"),
          active: drawerNodes === "settings",
        },
        {
          text: "Notes",
          onClick: () => setDrawerNodes("notes"),
          active: drawerNodes === "notes",
        },
      ]}
    >
      {drawerNodes === "settings" ? (
        <SettingsDrawerNodes task={task} />
      ) : (
        <NotesDrawerNodes task={task} />
      )}
    </Drawer>
  );
};

export { SettingsDrawerNodes };
export default ActivityDrawer;
