import React from "react";
import logo from "../../../img/Logo.png";
import "./index.css";

const Logo = () => {
  return (
    <div className="logo-container">
      <img className="logo-img" src={logo} alt="Deku" />
    </div>
  );
};

export default Logo;
