import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import "./close.css";

const Close = ({ onClick }) => {
  return (
    <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={onClick} />
  );
};

export { Close };
