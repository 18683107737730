import React from "react";
import "./emptystate.css";
import emptystateimg from "./emptystate.png";

const EmptyState = ({ message, children, image = emptystateimg }) => {
  return (
    <div className="emptystate-container">
      <div className="emptystate-message">{message}</div>
      <div className="emptystate-children">{children}</div>
    </div>
  );
};

export { EmptyState };
