import React, { useEffect } from "react";
import { Stack } from "../../../components/common";
import { useSelector, useDispatch } from "react-redux";
import {
  resetChangedNodeId,
  nodeStatus as getNodeStatus,
  selectCompletedTasks,
} from "../nodeSlice";

import TaskCard from "./TaskCard";

const TasksPageComplete = ({ projectId }) => {
  const tasks = useSelector(selectCompletedTasks).filter(
    (task) => task.type === "task"
  );

  const dispatch = useDispatch();
  const nodeStatus = useSelector(getNodeStatus);

  useEffect(() => {
    return () => {
      dispatch(resetChangedNodeId());
    };
  }, [dispatch]);
  return (
    <>
      <Stack
        emptyState={{
          message: "No completed tasks",
          children: "Tasks will appear here once they are completed",
        }}
      >
        {tasks
          .sort((a, b) => {
            if (a.dateTodo < b.dateTodo) return 1;
            return -1;
          })
          .map((task) => {
            return (
              <TaskCard key={task._id} task={task} nodeStatus={nodeStatus} />
            );
          })}
      </Stack>
    </>
  );
};

export default TasksPageComplete;
