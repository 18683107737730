import React, { useState } from "react";
import "./login.css";
import loginbg from "../../img/Logo.png";
import { login, userStatus } from "./userSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleButton from "react-google-button";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = ({ loginStatus }) => {
  const dispatch = useDispatch();

  const status = useSelector(userStatus);

  const [loading, setLoading] = useState(status);

  if (loginStatus === "idle") {
    return;
  }

  if (loginStatus !== "failed") {
    return (
      <div className="login-form-container">
        <div className="login-form-header">
          <FontAwesomeIcon icon={faSpinnerThird} spin />
          <span style={{ marginLeft: 20 }}>Checking your login status</span>
        </div>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-form-header">Log in to Deku</div>
        <div className="login-form-subtitle">
          Or click here to Sign Up if you don't have an account
        </div>
        <div>
          {loading === "loading" ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          ) : (
            <GoogleButton
              onClick={() => {
                setLoading("loading");
                window.open(
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:5001/api/login"
                    : "https://baroloapp-b5cab816e5a6.herokuapp.com/api/login/callback",
                  "_self"
                );
              }}
            />
          )}
        </div>
      </div>
      <div className="login-image">
        <img src={loginbg} />
      </div>
    </div>
  );
};

export default Login;
