import React, { useState, useEffect } from "react";
import {
  Drawer,
  Node,
  Pill,
  TimePicker,
  DateDropdown,
  Projects,
  TextInput,
  Button,
  NodeSection,
} from "../../components/common";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Link, useNavigate } from "react-router-dom";
import {
  faArrowUpRightFromSquare,
  faDialOff,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import {
  selectActivityById,
  getActivityById,
  updateActivity,
  deleteActivity,
} from "./activitySlice";
import { useSelector, useDispatch } from "react-redux";
import useSecondsToTime from "../../hooks/useSecondsToTime";
import useTimeToSeconds from "../../hooks/useTimeToSeconds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectNode, updateNode } from "../node/nodeSlice";
import { SettingsDrawerNodes } from "../node/task/TaskDrawer";

dayjs.extend(duration);

const ActivityDrawerNodes = ({ activity }) => {
  const formattedDuration = useSecondsToTime([activity]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tempNarrative, setTempNarrative] = useState(
    activity.narrative || activity.title
  );

  const handleUpdate = (params) => {
    dispatch(updateActivity({ _id: activity._id, ...params }));
  };

  const handleDelete = () => {
    dispatch(deleteActivity({ _id: activity._id }));
    navigate("/activity");
  };

  const handleUpdateNarrative = () => {
    dispatch(updateNode({ _id: activity.nodeId, narrative: tempNarrative }));
  };

  useEffect(() => {
    setTempNarrative(activity.narrative || activity.title);
  }, [activity]);

  const loading = activity.loading || [];

  return (
    <>
      <NodeSection>
        <Node title="Date">
          {loading.includes("dateCreated") ? (
            <Pill icon={faSpinnerThird} />
          ) : (
            <DateDropdown
              startDate={new Date(activity.dateCreated)}
              changeDate={(dateCreated) => handleUpdate({ dateCreated })}
            />
          )}
        </Node>
      </NodeSection>
      <NodeSection>
        <Node title="Duration">
          <TimePicker
            type="duration"
            value={formattedDuration}
            onChange={(duration) => {
              const splitTime = duration.split(":");
              handleUpdate({
                duration: dayjs
                  .duration({
                    hours: splitTime[0],
                    minutes: splitTime[1],
                  })
                  .asSeconds(),
              });
            }}
          />
        </Node>

        <Node title="Start time">
          {loading.includes("dateCreated") ? (
            <Pill icon={faSpinnerThird} />
          ) : (
            <TimePicker
              type="time"
              value={dayjs(activity.dateCreated).format("HH:mm")}
              onChange={(startTime) => {
                const splitTime = startTime.split(":");
                const dateCreated = dayjs(activity.dateCreated)
                  .set("hour", splitTime[0])
                  .set("minute", splitTime[1]);
                handleUpdate({ dateCreated });
              }}
            />
          )}
        </Node>

        <Node title="End time" info="Duration will be updated">
          {loading.includes("dateCreated") ? (
            <Pill icon={faSpinnerThird} />
          ) : (
            <TimePicker
              type="time"
              value={dayjs(activity.dateCreated)
                .add(activity.duration, "seconds")
                .format("HH:mm")}
              onChange={(endTime) => {
                const splitTime = endTime.split(":");
                const startTimeDate = dayjs(activity.dateCreated);
                const endTimeDate = dayjs(activity.dateCreated)
                  .set("hour", splitTime[0])
                  .set("minute", splitTime[1]);

                if (endTimeDate.isBefore(startTimeDate)) return;

                const diff = endTimeDate.diff(startTimeDate, "second");
                handleUpdate({ duration: diff });
              }}
            />
          )}
        </Node>
      </NodeSection>
      <NodeSection>
        <Node title="Narrative" info="Changes will affect all time entries">
          <TextInput
            onChange={setTempNarrative}
            onSubmit={handleUpdateNarrative}
            value={tempNarrative}
          />
        </Node>
        <Node title={`${activity.type} details`}>
          <Link to={`/tasks/${activity.nodeId}`}>
            <Pill
              color="blue"
              icon={faArrowUpRightFromSquare}
              responsive={false}
            >
              Open {activity.type}
            </Pill>
          </Link>
        </Node>
      </NodeSection>
      <NodeSection>
        <Node extend>
          <Button type="danger" onClick={handleDelete}>
            {activity.loading === "delete" ? (
              <Pill icon={faSpinnerThird} />
            ) : (
              <>Delete Activity</>
            )}
          </Button>
        </Node>
      </NodeSection>
    </>
  );
};

const ActivityDrawer = ({ activityId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activity = useSelector((state) =>
    selectActivityById(state, activityId)
  );
  const [drawerNodes, setDrawerNodes] = useState("time");
  useEffect(() => {
    if (!activity && activityId) {
      dispatch(getActivityById(activityId));
    }
  }, [dispatch, activity, activityId]);

  const node = useSelector((state) =>
    activity ? selectNode(state, activity.nodeId) : false
  );

  if (!activity) {
    return null;
  }

  return (
    <Drawer
      loading={activity.loading}
      visible={!!activityId}
      title={node && node.title}
      onClose={() => navigate("/activity")}
      navigation={[
        {
          text: "Time",
          active: drawerNodes === "time",
          onClick: () => setDrawerNodes("time"),
        },
        {
          text: node.type === "task" ? "Task" : "Meeting",
          active: drawerNodes === "node",
          onClick: () => setDrawerNodes("node"),
        },
      ]}
    >
      {drawerNodes === "time" ? (
        <ActivityDrawerNodes activity={{ ...node, ...activity }} />
      ) : (
        <SettingsDrawerNodes task={node} />
      )}
    </Drawer>
  );
};

export default ActivityDrawer;
