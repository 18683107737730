import React, { useEffect } from "react";
import Sidebar from "./components/sidebar";
import Header from "./features/header";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTimer, getUser, userStatus } from "./features/user/userSlice";
import { getNodes, nodeStatus } from "./features/node/nodeSlice";
import { getActivity, activityStatus } from "./features/activity/activitySlice";
import { getProjects, projectStatus } from "./features/projects/projectSlice";
import Toast from "./features/toast";
import Login from "./features/user/Login";
import { Tooltip } from "react-tooltip";
import dayjs from "dayjs";

const App = () => {
  const dispatch = useDispatch();

  const loginStatus = useSelector(userStatus);
  const status = useSelector(nodeStatus).status;
  const projectLoadStatus = useSelector(projectStatus);
  const activityLoadStatus = useSelector(activityStatus);

  useEffect(() => {
    if (loginStatus === "idle") dispatch(getUser());
  }, [loginStatus, dispatch]);

  useEffect(() => {
    if (status === "idle") dispatch(getNodes());
  }, [status, dispatch]);

  useEffect(() => {
    if (projectLoadStatus === "idle") dispatch(getProjects());
  }, [projectLoadStatus, dispatch]);

  useEffect(() => {
    if (activityLoadStatus === "idle" && status === "success") {
      dispatch(getActivity(dayjs().format("YYYY-MM-DD")));
    }
  }, [activityLoadStatus, dispatch, status]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Refresh timer
      dispatch(getTimer());
    }, 120000);
    return () => {
      clearInterval(interval);
    };
  });

  if (loginStatus !== "success") {
    return <Login loginStatus={loginStatus} />;
  }

  return (
    <div className="app-container">
      <Sidebar />
      <div className="content-container">
        <Header />
        <Outlet />
      </div>{" "}
      <Toast />
    </div>
  );
};

export default App;
