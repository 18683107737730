import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);

const useIsTimeBeforeOrCurrent = (time) => {
  // Current time, rounded down to the nearest minute
  const now = dayjs().startOf("minute");

  // Parse the provided time for today
  const providedTime = dayjs()
    .hour(parseInt(time.split(":")[0]))
    .minute(parseInt(time.split(":")[1]));

  // Check if the provided time is before or the same as the current time
  return providedTime.isSameOrBefore(now);
};

export default useIsTimeBeforeOrCurrent;
