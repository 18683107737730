import React, { useState, useEffect } from "react";

// Updated useResponsiveStyle hook to return a screen size category
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState("desktop");

  const updateScreenSize = () => {
    const width = window.innerWidth;
    if (width < 600) {
      setScreenSize(1);
    } else if (width >= 600 && width < 1200) {
      setScreenSize(2);
    } else {
      setScreenSize(3);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    // Set initial screen size
    updateScreenSize();

    // Cleanup function to remove event listener on component unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return screenSize;
};

export default useScreenSize;
