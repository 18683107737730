import React, { useState, useEffect, forwardRef } from "react";
import "./textinput.css";
import useAutoFocusInput from "../../../hooks/useAutoFocusInput";
import useMergedRefs from "../../../hooks/useMergedRefs";
import useClickOutside from "../../../hooks/useClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextInput = forwardRef(
  (
    {
      value = "",
      onChange = () => {},
      onBlur = () => {},
      onSubmit = () => {},
      input = false,
      placeholder,
      type = "title",
      icon,
      disabled = false,
      autoHighlight = true,
      multiLine = false,
      height,
      className,
    },
    ref
  ) => {
    const { showInput, setShowInput, inputRef, showAndFocusInput } =
      useAutoFocusInput(autoHighlight);

    const mergedRefs = useMergedRefs(inputRef, ref);

    const handleSubmit = () => {
      setShowInput(false);
      onSubmit();
    };

    const handleBlur = () => {
      onBlur();
      setShowInput(false);
    };

    useClickOutside({
      ref: inputRef,
      focusState: showInput,
      requireShift: multiLine,
      onSubmit: handleSubmit,
      onBlur: handleBlur,
    });

    return (
      <div
        className={`textinput-container ${icon && "textinput-icon"} ${
          !onChange || showInput
            ? "textinput-nohover"
            : "textinput-container-hover"
        } ${multiLine ? "textinput-multiline" : "textinput-singleline"} ${
          className || ""
        }`}
        onClick={onChange ? showAndFocusInput : () => {}}
        onBlur={handleBlur}
        style={{ height }}
      >
        {onChange && (showInput || input) ? (
          !multiLine ? (
            <input
              type="text"
              ref={mergedRefs}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => onChange(event.target.value)}
              onBlur={handleBlur}
              className={`textinput-value textinput-input ${
                type === "inline" ? "textinput-inline" : ""
              } ${disabled ? "textinput-disabled" : ""}`}
            />
          ) : (
            <div>
              <textarea
                className="textinput-value textinput-input"
                ref={mergedRefs}
                placeholder={placeholder}
                disabled={disabled}
                onClick={(event) => event.stopPropagation()}
                onChange={(event) => onChange(event.target.value)}
                onBlur={handleBlur}
                style={{ height }}
              >
                {value}
              </textarea>{" "}
              <span style={{ fontSize: 11, marginLeft: 10 }}>
                Press SHIFT+Enter for a new line
              </span>
            </div>
          )
        ) : (
          <div className={`textinput-value`}>
            {icon && (
              <FontAwesomeIcon className="textinput-icon-icon" icon={icon} />
            )}
            {multiLine ? (
              <pre style={{ whiteSpace: "pre-wrap", height }}>
                {value || placeholder}
              </pre>
            ) : (
              value || placeholder
            )}
          </div>
        )}{" "}
      </div>
    );
  }
);

export { TextInput };
