import React, { useEffect } from "react";
import { Stack } from "../../../components/common";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNodes,
  selectPinnedNodes,
  resetChangedNodeId,
  nodeStatus as getNodeStatus,
} from "../nodeSlice";

import TaskCard from "./TaskCard";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

const TasksPageDefault = ({ type, projectId }) => {
  const pinnedTasks = useSelector(selectPinnedNodes, "pinned").filter(
    (task) => task.type === type
  );
  const tasks = useSelector(selectNodes, "active").filter(
    (task) => task.type === type
  );
  const dispatch = useDispatch();
  const nodeStatus = useSelector(getNodeStatus);

  useEffect(() => {
    return () => {
      dispatch(resetChangedNodeId());
    };
  }, [dispatch]);
  return (
    <>
      <Stack showEmptyState={false} title="Pinned">
        {pinnedTasks
          .filter((task) => {
            const projectValidation = projectId
              ? task.projectId === projectId
              : true;
            return projectValidation;
          })
          .sort((a, b) => {
            if (a.dateTodo > b.dateTodo) return 1;
            return -1;
          })
          .map((task) => {
            return (
              <TaskCard
                key={task._id}
                task={task}
                nodeStatus={nodeStatus}
                urgent={dayjs(task.dateTodo).isBefore(dayjs(), "day")}
              />
            );
          })}
      </Stack>
      <Stack
        emptyState={{
          message: "No tasks today",
          children: "Any upcoming tasks are shown below",
        }}
        title="Today"
      >
        {tasks
          .filter((task) => {
            const dayValidation = dayjs(task.dateTodo).isSameOrBefore(
              dayjs(),
              "day"
            );
            const projectValidation = projectId
              ? task.projectId === projectId
              : true;
            return dayValidation && projectValidation;
          })
          .sort((a, b) => {
            if (a.dateTodo > b.dateTodo) return 1;
            return -1;
          })
          .map((task) => {
            return (
              <TaskCard
                key={task._id}
                task={task}
                nodeStatus={nodeStatus}
                urgent={dayjs(task.dateTodo).isBefore(dayjs(), "day")}
              />
            );
          })}
      </Stack>
      <Stack title="Upcoming" showEmptyState={false}>
        {tasks
          .filter((task) => {
            const dayValidation = dayjs(task.dateTodo).isAfter(dayjs(), "day");
            const projectValidation = projectId
              ? task.projectId === projectId
              : true;
            return dayValidation && projectValidation;
          })
          .sort((a, b) => {
            if (a.dateTodo > b.dateTodo) return 1;
            return -1;
          })
          .map((task) => {
            return (
              <TaskCard key={task._id} task={task} nodeStatus={nodeStatus} />
            );
          })}
      </Stack>
    </>
  );
};

export default TasksPageDefault;
