import React from "react";
import { Provider, useDispatch } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import "./App.css";

import App from "./App";
import reducers from "./reducers";

const loggerMiddleware = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("Dispatching:", action);
    let result = next(action);
    console.log("Next State:", store.getState());

    return result;
  }
  return next(action);
};

const defaultStore = createStore(
  reducers,
  {},
  applyMiddleware(reduxThunk, loggerMiddleware)
);

const Root = ({ children, store = defaultStore }) => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default Root;
