import React from "react";
import "./index.css";
import { useDispatch } from "react-redux";
import { logout } from "../../features/user/userSlice";
import Logo from "./logo";
import Menu from "./menu";
import {
  faWavePulse,
  faCheck,
  faVideo,
  faCog,
  faArrowLeft,
  faBriefcase,
  faTachometerAltAverage,
  faHouseDay,
  faCalendarRange,
  faCalendarCircleUser,
  faNoteSticky,
  faNotes,
} from "@fortawesome/pro-solid-svg-icons";

const Sidebar = () => {
  const dispatch = useDispatch();
  return (
    <div className="sidebar-container">
      <Logo />
      <Menu
        items={[
          {
            icon: faCalendarCircleUser,
            text: "Day",
            link: "/tasks",
            isDefault: true,
          },
          { icon: faWavePulse, text: "Activity", link: "/activity" },
          { icon: faNotes, text: "Notes", link: "/notes" },

          { icon: faBriefcase, text: "Projects", link: "/projects" },
        ]}
      />
      <div className="sidebar-bottom">
        <Menu
          items={[
            {
              icon: faArrowLeft,
              text: "Logout",
              onClick: () => dispatch(logout()),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Sidebar;
