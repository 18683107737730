import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./pill.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { faSlash } from "@fortawesome/pro-light-svg-icons";
import useScreenSize from "../../../hooks/useScreenSize";
import "react-tooltip/dist/react-tooltip.css";
import useProjectColors from "../../../hooks/useProjectColors";

const Pill = ({
  icon,
  iconColor,
  children,
  maxWidth,
  width,
  onClick,
  onFocus,
  expand,
  emphasis,
  spin,
  fontSize,
  style,
  textStyle,
  className,
  hoverIcon,
  backgroundHover = true,
  selected,
  tooltip = { _id: false, label: false },
  color,
  responsive = false,
  slash = false,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const screenSize = useScreenSize();
  const showText = !responsive || screenSize >= 3;
  const tooltipId = `${tooltip._id}${tooltip.label}`;

  const colors = useProjectColors();

  return (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltip.label}
      style={{ ...style, width }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      tabIndex={onClick ? 0 : -1}
      onFocus={onFocus ? () => onFocus(true) : () => {}}
      onBlur={onFocus ? () => onFocus(false) : () => {}}
      className={`pill-container ${onClick ? "pill-button" : ""} ${
        expand ? "pill-expand" : ""
      } ${emphasis && "pill-emphasis"} ${selected && "pill-selected"} ${
        backgroundHover && "background-hover"
      } ${className}`}
      onClick={(event) => {
        if (onClick) {
          event.stopPropagation();
          event.preventDefault();
          onClick();
        }
      }}
    >
      {icon ? (
        <FontAwesomeIcon
          spin={icon.iconName === "spinner-third"}
          className="pill-icon"
          icon={hover && hoverIcon ? hoverIcon : icon}
          style={{ color: color ? colors[color] : iconColor, fontSize }}
        />
      ) : (
        ""
      )}

      {slash ? (
        <FontAwesomeIcon
          style={{ color: color ? colors[color] : iconColor, fontSize }}
          icon={faSlash}
          className="pill-slash"
        />
      ) : (
        ""
      )}

      {children && (showText || expand || props.showText) && (
        <div
          className="pill-text"
          style={{
            maxWidth,
            fontSize,
            color: color ? colors[color] : "",
            ...textStyle,
          }}
        >
          {children}
        </div>
      )}
      {tooltip._id && !showText && <Tooltip id={tooltipId} />}
    </div>
  );
};

export { Pill };
