import React from "react";
import { Filters, DateDropdown } from "../../../components/common";

const MeetingFilters = ({ dateFilter, changeDateFilter }) => {
  return (
    <Filters>
      <DateDropdown
        fontSize={15}
        changeDate={changeDateFilter}
        startDate={dateFilter}
      />
    </Filters>
  );
};

export default MeetingFilters;
