import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { selectTimer } from "../features/user/userSlice";
dayjs.extend(duration);

const useTimeElapsed = (format = false, running = true, maxTime) => {
  const timer = useSelector(selectTimer);
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    // Ensure startTimer is defined and is a valid date
    if (timer && running) {
      const startTime = dayjs(timer.startTime);
      const currentTime = dayjs();
      const initialElapsed = currentTime.diff(startTime, "second");
      if (!maxTime || initialElapsed < maxTime) {
        setTimeElapsed(initialElapsed);
      } else {
        setTimeElapsed(maxTime);
      }

      const interval = setInterval(() => {
        setTimeElapsed((prevElapsed) => prevElapsed + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, running]);

  if (format) {
    return dayjs
      .duration(timeElapsed, "seconds")
      .format(timeElapsed > 3600 ? "H[h] m[m]" : "m[m] s[s]");
  }

  return timeElapsed;
};

export default useTimeElapsed;
