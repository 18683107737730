import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import "./dropdown.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/pro-light-svg-icons";
import { redirect } from "react-router-dom";

const DropdownIndicator = () => <FontAwesomeIcon icon={faChevronDown} />;

const ClearIndicator = () => {};

const Dropdown = ({ name, options, style, isSearchable = false, ...props }) => {
  const border = "0px";

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: 15,
      border: `${border} solid orange`,
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "Lato, sans-serif",
      color: "#939393",
      fontSize: 15,
      border: `${border} solid pink`,
      padding: "10px 10px",
      ...(!state.isSelected && {
        ":hover": {
          color: "#000",
          cursor: "pointer",
        },
      }),
      ":focus": {
        backgroundColor: "transparent",
      },
      ...(state.isFocused && {
        backgroundColor: "transparent",
      }),
      ...(state.isSelected && {
        color: "#009eff",
        cursor: "pointer",

        backgroundColor: "transparent",
      }),
    }),
    indicatorSeparator: (provided, state) => ({
      display: "none",
      border: `${border} solid aqua`,
    }),
    control: (provided, state) => ({
      ...provided,
      fontFamily: "Lato, sans-serif",
      outline: "none",
      borderColor: "transparent",
      boxShadow: "none",
      borderRadius: 13,
      border: "0px",
      backgroundColor: "transparent",
      color: "#939393",
      cursor: "pointer",
      margin: 0,
      color: "#666",
      border: `${border} solid black`,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      fontSize: 15,
      minWidth: 120,
      margin: 0,
      padding: 0,
      border: `${border} solid grey`,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: 10,
      fontSize: 15,
      border: `${border} solid fushica`,
    }),
    input: (provided, state) => ({
      ...provided,
      padding: 5,
      border: `${border} solid blue`,
    }),
    menuOption: (provided, state) => ({
      ...provided,
      border: `${border} solid red`,
      fontFamily: "Lato, sans-serif",
      fontSize: 10,
      color: "red",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      border: `${border} solid green`,
    }),
    selectContainer: (provided, state) => ({
      ...provided,
      border: `${border} solid purple`,
      border: "1px solid #cccccc",
      borderRadius: 5,
      padding: "7px 10px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      border: `${border} solid maroon`,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      border: `${border} solid red`,
      color: "#939393",
    }),
  };

  return (
    <div
      className="dropdown-container"
      onClick={(event) => event.preventDefault()}
    >
      <Select
        onMouseDown={(event) => event.preventDefault()}
        isSearchable={isSearchable}
        onClick={(event) => event.preventDefault()}
        name={name}
        options={options}
        menuPortalTarget={document.body}
        classNamePrefix="dropdown"
        styles={{ ...customStyles, ...style }}
        components={{ DropdownIndicator, ClearIndicator }}
        isDisabled={!props.onChange}
        {...props}
      />
    </div>
  );
};

export { Dropdown };
