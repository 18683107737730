import { useEffect, useState } from "react";

const useCtrlEnter = ({ ref = false, onSubmit, onBlur }) => {
  const [ctrlPressed, setCtrlPressed] = useState();

  // Call the callback when the Enter or Escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Control" || event.key === "Meta") {
      setCtrlPressed(true);
    }

    if (event.key === "Enter") {
      if (ctrlPressed) onSubmit();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Control" || event.key === "Meta") {
      setCtrlPressed(false);
    }
  };

  useEffect(() => {
    // Add event listener to the document for outside click and keydown
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
    };
  }, [ref, onSubmit, onBlur]);

  return ref;
};

export default useCtrlEnter;
