import React, { useState } from "react";
import {
  Filters,
  Filter,
  Button,
  DateDropdown,
  Pill,
  Toggle,
  Navigation,
} from "../../components/common";
import {
  faCheck,
  faVideo,
  faEnvelope,
  faAbacus,
  faUniversity,
  faGrid,
  faList,
  faClock,
  faStopwatch,
  faBriefcase,
  faMagicWandSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import "./activityfilters.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFormatSeconds from "../../hooks/useFormatSeconds";
const ActivityFilters = ({
  changeFilterType,
  changeGroupingType,
  changeFilterDate,
  filterDate,
  totalTime = 0,
  showNarratives,
  setShowNarratives,
  groupingType = false,
}) => {
  const total = useFormatSeconds(totalTime);

  return (
    <Filters>
      <div>
        <Navigation
          active={groupingType === false}
          text="Time"
          onClick={() => changeGroupingType(false)}
          icon={faStopwatch}
        />
        <Navigation
          active={groupingType === "nodeId"}
          text="Task/Meeting"
          onClick={() => changeGroupingType("nodeId")}
          icon={faCheck}
        />
        <Navigation
          active={groupingType === "projectId"}
          text="Project"
          onClick={() => changeGroupingType("projectId")}
          icon={faBriefcase}
        />
      </div>{" "}
      <div>
        <DateDropdown
          changeDate={(value) => changeFilterDate(dayjs(value))}
          startDate={new Date(filterDate)}
          className="activity-filter"
        />{" "}
        <Pill icon={faClock} responsive={false}>
          {total}
        </Pill>
      </div>
    </Filters>
  );
};

export default ActivityFilters;
