import React, { useState } from "react";
import {
  Page,
  Title,
  Grid,
  Card,
  Button,
  Pill,
  Filter,
  Filters,
  Navigation,
  Projects,
  TextInput,
} from "../../components/common";
import { faNotes } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectNotes } from "../node/nodeSlice";
import TaskDrawer from "../node/task/TaskDrawer";

import Note from "./Note";
import { faFilter, faSearch } from "@fortawesome/pro-light-svg-icons";
import { faText } from "@fortawesome/pro-solid-svg-icons";

const NotesPage = () => {
  const [drawerIsVisible, setDrawerIsVisible] = useState();
  const [projectFilter, setProjectFilter] = useState();
  const [textFilter, setTextFilter] = useState();

  const { noteId } = useParams();

  const notes = useSelector(selectNotes)
    .filter(
      ({ projectId, title, notes }) =>
        (!projectFilter || projectFilter === projectId) &&
        (!textFilter ||
          title.toLowerCase().includes(textFilter.toLowerCase()) ||
          notes.toLowerCase().includes(textFilter.toLowerCase()))
    )
    .sort((a, b) => {
      if (a.dateModified < b.dateModified) return 1;
      return -1;
    });

  return (
    <Page
      drawer={
        <TaskDrawer taskId={noteId} key={noteId} defaultDrawerNodes="notes" />
      }
      drawerIsVisible={noteId}
    >
      <Title icon={faNotes}>Notes</Title>
      <Filters>
        {" "}
        <Pill icon={faSearch}>
          {" "}
          <TextInput
            className="notes-filter"
            onChange={setTextFilter}
            value={textFilter}
            placeholder={"Type to filter"}
          />
        </Pill>{" "}
        <div
          style={{
            display: "flex",
            color: "#999",
            alignItems: "center",
            fontSize: 14,
            gap: 10,
          }}
        >
          <Pill icon={faFilter}>Filter by project</Pill>
          <Projects
            responsive={false}
            onChange={(value) => setProjectFilter(value.value)}
          />{" "}
        </div>
      </Filters>
      <Grid
        emptyState={{
          message: "No notes yet",
        }}
      >
        {notes.map((note) => (
          <Note
            _id={note._id}
            title={note.title}
            text={note.notes}
            dateModified={note.dateModified}
            projectId={note.projectId}
          />
        ))}
      </Grid>
    </Page>
  );
};

export default NotesPage;
