import React from "react";
import "./node.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";

const Node = ({ title, children, open, info, type, extend, vertical }) => {
  return (
    <div
      className={`node-container ${type === "list" && "node-container-list"}`}
    >
      {title && <div className="node-title">{title}</div>}
      {info && (
        <div className="node-info">
          {" "}
          <FontAwesomeIcon icon={faInfoCircle} />
          {info}
        </div>
      )}
      <div
        className="node-children"
        style={{ display: extend || vertical ? "block" : "flex" }}
      >
        {children}
      </div>
      {open && (
        <div className="node-onopen" onClick={open.onClick}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          {open.text}
        </div>
      )}
    </div>
  );
};

export { Node };
