import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Pill, DateDropdown, Projects } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { setTimer, selectTimer } from "../../user/userSlice";
import {
  faPlayCircle,
  faStop,
  faCircle,
  faCheckCircle,
  faArrowDown,
  faArrowUp,
  faCheck,
  faVideo,
  faCalendarAlt,
  faClock,
  faThumbTack,
  faSlash,
} from "@fortawesome/pro-light-svg-icons";
import { updateNode } from "../nodeSlice";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TaskCard = ({ task, className, nodeStatus, urgent }) => {
  const dispatch = useDispatch();
  const timer = useSelector(selectTimer);
  const changedNodeId = nodeStatus.changeNodeId;
  const taskIsLoading = nodeStatus.taskIsLoading;

  const isActiveNode = timer.nodeId === task._id && timer.active;
  const isToday = dayjs(task.dateTodo).isSameOrBefore(dayjs(), "day");

  const handleTimer = () => {
    dispatch(
      setTimer({
        nodeId: task._id,
        startTime: new Date(),
        active: !isActiveNode,
        action: isActiveNode ? "stop" : "start",
      })
    );
  };

  const handleComplete = async () => {
    await dispatch(
      updateNode({
        _id: task._id,
        status: task.status === "active" ? "complete" : "active",
      })
    );
  };

  const handlePin = async () => {
    await dispatch(
      updateNode({
        _id: task._id,
        status: task.status === "active" ? "pinned" : "active",
      })
    );
  };

  const handleProject = async (project) => {
    await dispatch(updateNode({ _id: task._id, projectId: project.value }));
  };

  const handleWait = async () => {
    const dateTodo = isToday
      ? dayjs().startOf("day").add(1, "day")
      : dayjs().startOf("day");
    await dispatch(updateNode({ _id: task._id, dateTodo }));
  };

  const loading = task.loading || [];
  const params = useParams();

  return (
    <Link to={`/tasks/${task._id}`}>
      <Card
        key={task._id}
        className={`${params.taskId === task._id ? "task-active" : ""}`}
        recording={isActiveNode}
        loading={taskIsLoading}
        selected={params.taskId === task._id}
        changed={changedNodeId === task._id}
      >
        <div className="card-action">
          {task.type === "task" && (
            <Pill
              tooltip={{ _id: task._id, label: "Pin" }}
              icon={faThumbTack}
              onClick={taskIsLoading ? () => {} : handlePin}
              className="taskspage-complete-icon"
              iconColor="#009eff"
              slash={task.status === "pinned"}
              fontSize={15}
            />
          )}
        </div>
        <div className="card-action">
          {task.type === "task" && (
            <Pill
              tooltip={{ _id: task._id, label: "Complete" }}
              icon={loading.includes("status") ? faSpinnerThird : faCheck}
              hoverIcon={
                loading.includes("status") ? faSpinnerThird : faCheckCircle
              }
              onClick={taskIsLoading ? () => {} : handleComplete}
              className="taskspage-complete-icon"
              iconColor="#009eff"
              fontSize={15}
            />
          )}
        </div>
        {loading.includes("projectId") ? (
          <Pill icon={faSpinnerThird} style={{ width: "130px" }} />
        ) : (
          <Projects value={task.projectId} onChange={handleProject} />
        )}
        <Pill
          expand
          textStyle={{
            textDecoration:
              task.status === "complete" ? "line-through" : "default",
          }}
        >
          {task.title}
        </Pill>
        {task.status === "active" && task.type === "task" && (
          <Pill
            icon={
              loading.includes("dateTodo")
                ? faSpinnerThird
                : isToday
                ? faArrowDown
                : faArrowUp
            }
            onClick={handleWait}
            tooltip={{ _id: task._id, label: isToday ? "Tomorrow" : "Today" }}
            className="taskspage-complete-icon"
          >
            {isToday ? "Tomorrow" : "Today"}
          </Pill>
        )}
        <Pill
          icon={
            loading.includes("timer")
              ? faSpinnerThird
              : isActiveNode
              ? faStop
              : faPlayCircle
          }
          onClick={handleTimer}
          tooltip={{ _id: task._id, label: isActiveNode ? "Stop" : "Start" }}
          className={
            isActiveNode ? "tasks-active" : timer.active && "tasks-inactive"
          }
        >
          {isActiveNode ? "Stop" : "Start"}
        </Pill>
      </Card>
    </Link>
  );
};

export default TaskCard;
