import React, { useState } from "react";
import "./button.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Dropdown } from "../dropdown/Dropdown";
import { components } from "react-select";

const DropdownIndicator = (props) => {
  const onIndicatorClick = (e) => {
    e.stopPropagation();
    props.selectProps.onDropdownIndicatorClick();
  };
  return (
    <div onClick={onIndicatorClick}>
      <components.DropdownIndicator {...props}></components.DropdownIndicator>
    </div>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.icon && <FontAwesomeIcon icon={props.data.icon} />}{" "}
      {props.children}
    </components.Option>
  );
};

const Button = ({
  children,
  icon,
  type = "primary",
  onClick = false,
  options = [],
  disabled = false,
  width = 130,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  if (!options.length) {
    return (
      <div
        className={`button-container button-nodropdown ${
          disabled ? "button-disabled" : "button-enabled"
        } ${`button-${type}`}`}
        onClick={!disabled ? onClick : () => {}}
      >
        {icon ? (
          <FontAwesomeIcon
            spin={icon.iconName === "spinner-third"}
            icon={icon}
          />
        ) : (
          ""
        )}
        <div className="button-children">{children || "Button"}</div>
      </div>
    );
  }

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      width: 170,
      right: 100,
      minWidth: 100,
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: 13,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      margin: 0,
      color: "#fff",
      ":hover": {
        color: "#fff",
        cursor: "pointer",
        backgroundColor: "#1d8cd0",
        transition: "background-color 0.3s",
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      margin: 0,
      display: "flex",
      gap: 10,
      height: 45,
      borderBottom: "1px solid #dbdbdb",
      alignItems: "center",
      fontFamily: "Lato, sans-serif",
      fontSize: 13,
      color: "#666",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
      textOverflow: "ellipsis",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      fontSize: 14,
      fontWeight: 600,
      margin: 0,
      padding: 12,

      ":hover": {
        cursor: "pointer",
        backgroundColor: "#1d8cd0",
        transition: "background-color 0.3s",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      gap: 0,
      border: 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#fff",
      fontSize: 14,
      fontWeight: 600,
      margin: 0,
      padding: 0,
      ":hover": {
        cursor: "pointer",
        backgroundColor: "#1d8cd0",
        transition: "background-color 0.3s",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
    }),
    control: (provided, state) => ({
      ...provided,
      fontFamily: "Lato, sans-serif",
      outline: "none",
      borderColor: "transparent",
      boxShadow: "none",
      borderRadius: 8,
      border: 0,
      backgroundColor: "#009eff",
      color: "#939393",
      gap: 0,
      cursor: "pointer",
      display: "flex",

      ":hover": {
        cursor: "pointer",
        backgroundColor: !onClick ? "#1d8cd0" : "#009eff",
        transition: "background-color 0.3s",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
    }),
  };

  const onChange = (option) => {
    setMenuIsOpen(!menuIsOpen);
    if (option.onClick) return option.onClick();
    return;
  };

  return (
    <Dropdown
      components={{ DropdownIndicator, Option }}
      styles={customStyles}
      options={options}
      className={disabled ? "button-disabled" : "button-enabled"}
      menuPosition="fixed"
      isDisabled={disabled}
      isSearchable={false}
      onDropdownIndicatorClick={() => setMenuIsOpen(!menuIsOpen)}
      onChange={onChange}
      menuIsOpen={menuIsOpen}
      onBlur={() => setMenuIsOpen(false)}
      value={false}
      placeholder={
        <div
          className={`button-container button-dropdown ${
            onClick ? "button-noclick" : ""
          }`}
          onClick={onClick ? onClick : () => setMenuIsOpen(!menuIsOpen)}
        >
          <FontAwesomeIcon icon={icon} />
          {children}
        </div>
      }
    />
  );
};

export { Button };
