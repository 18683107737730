import React from "react";
import { Title } from "../";
import "./page.css";
import { useNavigate } from "react-router-dom";

const Page = ({
  filters = false,
  children,
  drawer,
  title,
  titleIcon,
  drawerIsVisible,
  source,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`page-drawer-container ${
        drawerIsVisible && "page-drawer-container-visible"
      }`}
    >
      <div className="page-container">
        {title && <Title icon={titleIcon}>{title}</Title>}

        {filters}
        {children}
      </div>
      <div
        className="page-container-overlay"
        onClick={() => navigate(source)}
      />
      {drawerIsVisible ? drawer : null}
    </div>
  );
};

export { Page };
