import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const useDetectProject = (text) => {
  const projects = useSelector((state) => state.project.projects); // Assuming projects are stored in state.projects
  const [detectedProject, setDetectedProject] = useState();

  useEffect(() => {
    if (text && projects) {
      // Find the first project where a keyword is present in the text
      const matchingProject = projects.find((project) => {
        const keywords = project.keywords || [];
        return keywords.some(
          (keyword) =>
            keyword !== null &&
            text.toLowerCase().includes(keyword.toLowerCase())
        );
      });

      // If a matching project is found, update the state
      if (matchingProject !== false) {
        setDetectedProject(matchingProject);
      }
    } else {
      setDetectedProject(false);
    }
  }, [text, projects]);

  return detectedProject;
};

export default useDetectProject;
