import React, { useState, useEffect } from "react";
import { Card, Projects, Pill, TextInput } from "../../components/common";
import "./note.css";
import dayjs from "dayjs";
import {
  faCalendar,
  faCheck,
  faClipboard,
  faFileWord,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { Paragraph, Document, Packer, TextRun } from "docx";
import { saveAs } from "file-saver";

const exportToWord = async ({ filename, contents, setLoading }) => {
  setLoading("word");
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: contents.split("\n").map(
          (text) =>
            new Paragraph({
              children: [new TextRun(text)],
            })
        ),
      },
    ],
  });
  const blob = await Packer.toBlob(doc);
  // saveAs from FileSaver will download the file
  await saveAs(blob, `${filename}.docx`);

  setTimeout(() => setLoading(false), 1000);
};

const Note = ({
  onClick,
  _id,
  title = "",
  text = "",
  dateModified,
  projectId,
}) => {
  const [copied, setCopied] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 3000);
  }, [copied]);
  return (
    <Link to={`/notes/${_id}`}>
      <Card onClick={onClick}>
        <div className="note-title">{title}</div>
        <div className="note-details">
          <Projects value={projectId} responsive={false} />
          <Pill icon={faCalendar}>
            {dayjs(dateModified).format("D MMMM YYYY")}
          </Pill>
        </div>
        <div className="note-text">{text}</div>
        <div className="note-tools">
          <Pill
            icon={copied ? faCheck : faClipboard}
            color={copied ? "blue" : "grey"}
            onClick={() => {
              navigator.clipboard.writeText(`${title}
          
          ${text}`);
              setCopied(true);
            }}
          >
            Copy
          </Pill>
          <Pill
            icon={loading ? faSpinnerThird : faFileWord}
            color={loading ? "blue" : "grey"}
            spin={loading}
            onClick={() =>
              exportToWord({ filename: title, contents: text, setLoading })
            }
          >
            Export to Word
          </Pill>
        </div>{" "}
      </Card>
    </Link>
  );
};

export default Note;
