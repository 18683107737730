import React, { useState, useEffect } from "react";
import { Page, Stack } from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import ActivityCard from "./ActivityCard";
import ActivityDrawer from "./ActivityDrawer";
import ActivityFilters from "./ActivityFilters";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { faWavePulse } from "@fortawesome/pro-light-svg-icons";
import { activityStatus, getActivity } from "./activitySlice";
import { getNode, getNodes, nodeStatus, selectNodes } from "../node/nodeSlice";
import _ from "lodash";
import { Tooltip } from "react-tooltip";
import { useParams } from "react-router-dom";
import { selectTimer } from "../user/userSlice";
import useFormatSeconds from "../../hooks/useFormatSeconds";
dayjs.extend(duration);

const groupTime = ({ data, group, sort }) => {
  if (!group)
    return data.sort((a, b) => {
      if (a[sort] > b[sort]) return -1;
      if (b[sort] > a[sort]) return 1;
      return 0;
    });
  return Object.values(
    data.reduce((acc, curr) => {
      const nodeId = curr[group];

      if (acc[nodeId]) {
        return {
          ...acc,
          [nodeId]: {
            ...acc[nodeId],
            duration: acc[nodeId].duration + curr.duration,
            title:
              group !== "projectId" || acc[nodeId].title.includes(curr.title)
                ? acc[nodeId].title
                : `${acc[nodeId].title}. ${curr.title}`,
            narrative:
              group !== "projectId" || acc[nodeId].title.includes(curr.title)
                ? acc[nodeId].narrative
                : `${acc[nodeId].narrative || acc[nodeId].title}. ${
                    curr.narrative || curr.title
                  }`,
          },
        };
      }
      return {
        ...acc,
        [nodeId]: curr,
      };
    }, {})
  ).sort((a, b) => {
    if (a[sort] > b[sort]) return 1;
    if (b[sort] > a[sort]) return -1;
    return 0;
  });
};

const App = () => {
  const [filterType, changeFilterType] = useState();
  const [groupingType, changeGroupingType] = useState();
  const [filterDate, changeFilterDate] = useState(dayjs());
  const [enrichedTime, setEnrichedTime] = useState([]);
  const dispatch = useDispatch();
  const nodeLoadingStatus = useSelector(nodeStatus).status;
  const timer = useSelector(selectTimer);

  const time = useSelector((state) => state.activity.times);
  const activeTime = timer.active
    ? [
        {
          _id: "recording",
          nodeId: timer.nodeId,
          duration: dayjs().diff(dayjs(timer.startTime), "seconds"),
          dateCreated: timer.startTime,
        },
      ]
    : [];

  const nodes = useSelector((state) => state.node.nodes);
  const loadingNodes = useSelector((state) => state.activity.loadingNodes);

  const projects = useSelector((state) => state.project.projects);
  const [groupedTime, setGroupedTime] = useState([]);

  const activityLoadingStatus = useSelector(activityStatus);

  const [showNarratives, setShowNarratives] = useState(true);

  const dayTime = dayjs
    .duration(
      time.reduce((acc, curr) => {
        return acc + curr.duration;
      }, 0),
      "seconds"
    )
    .asHours()
    .toFixed(2);

  // When the date changes get the relevant activity if we don't already have it
  useEffect(() => {
    // Work out if we have the activity
    const activityLoaded = time.some(({ dateCreated }) =>
      dayjs(dateCreated).isSame(filterDate, "day")
    );
    if (!activityLoaded) dispatch(getActivity(filterDate.format("YYYY-MM-DD")));
  }, [filterDate]);

  // Make sure we have all the nodes we need
  useEffect(() => {
    const fetchMissingNodes = async () => {
      const missingNodes = [
        ...time,
        timer.active ? { nodeId: timer.nodeId } : {},
      ].reduce((acc, curr) => {
        if (
          curr.nodeId === null ||
          !curr.nodeId ||
          nodes.find((node) => node._id === curr.nodeId)
        )
          return acc;
        return [...acc, curr.nodeId];
      }, []);
      if (!missingNodes.length) return;
      await Promise.all(
        missingNodes.map((nodeId) => {
          if (
            !loadingNodes.includes(nodeId) &&
            !nodes.find((node) => node._id === nodeId)
          )
            dispatch(getNode({ _id: nodeId }));
        })
      );
    };

    fetchMissingNodes();
  }, [dispatch, activityLoadingStatus, time, nodes, projects]);

  useEffect(() => {
    // Enrich activities with node information
    const enrichedData = [...time, ...activeTime].map((time) => {
      if (!time) return false;
      const nodeInfo = nodes.find((node) => node._id === time.nodeId) || {};

      const projectInfo =
        projects.find((project) => project._id === nodeInfo.projectId) || {};
      return {
        ...time,
        title: nodeInfo.title,
        type: nodeInfo.type,
        status: nodeInfo.status,
        projectId: nodeInfo.projectId || false,
        projectTitle: projectInfo.title || "No project selected",
        narrative: nodeInfo.narrative,
      };
    });
    setEnrichedTime(enrichedData);
  }, [time, nodes]);

  useEffect(() => {
    setGroupedTime(
      groupTime({
        data: enrichedTime.filter(({ dateCreated }) =>
          dayjs(dateCreated).isSame(filterDate, "day")
        ),
        group: groupingType,
        sort: !groupingType ? "dateCreated" : "title",
      })
    );
  }, [groupingType, enrichedTime, filterDate]);

  const totalTime = groupedTime.reduce((acc, curr) => {
    return acc + curr.duration;
  }, 0);

  const { activityId } = useParams();

  return (
    <Page
      drawer={<ActivityDrawer activityId={activityId} />}
      drawerIsVisible={activityId}
      source="/activity"
      title="Activity"
      titleIcon={faWavePulse}
      filters={
        <ActivityFilters
          filterDate={filterDate}
          changeFilterDate={changeFilterDate}
          changeFilterType={changeFilterType}
          changeGroupingType={changeGroupingType}
          groupingType={groupingType}
          totalTime={totalTime}
          showNarratives={showNarratives}
          setShowNarratives={setShowNarratives}
        />
      }
    >
      <Stack loading={activityLoadingStatus === "loading"}>
        {groupedTime.map((node) => {
          return (
            <ActivityCard
              key={node._id}
              {...node}
              recording={node._id === "recording"}
              groupingType={groupingType}
              showNarratives={showNarratives}
            />
          );
        })}
      </Stack>{" "}
      <Tooltip id="my-tooltip" />
    </Page>
  );
};

export default App;
