import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pill, Card, TimePicker, Projects } from "../../components/common";
import {
  faTimer,
  faCheck,
  faCopy,
  faSpinnerThird,
  faWavePulse,
  faBriefcase,
  faPhone,
  faClock,
  faStop,
  faPlayCircle,
} from "@fortawesome/pro-light-svg-icons";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useFormatSeconds from "../../hooks/useFormatSeconds";
import { Link } from "react-router-dom";
import { selectTimer, setTimer } from "../user/userSlice";
dayjs.extend(duration);

const ActivityCard = ({
  _id,
  nodeId,
  dateCreated,
  duration,
  groupingType,
  showNarratives,
  recording,
  ...props
}) => {
  const [copiedStatus, setCopiedStatus] = useState(false);
  const text = showNarratives ? props.narrative || props.title : props.title;
  const timer = useSelector(selectTimer);
  const dispatch = useDispatch();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);

    setCopiedStatus(true);
  };

  useEffect(() => {
    if (copiedStatus) {
      setTimeout(() => setCopiedStatus(false), 2000);
    }
  }, [copiedStatus]);

  const formattedDuration = useFormatSeconds(duration);

  if (!props.title) {
    return (
      <Card>
        <Pill icon={faSpinnerThird} />
      </Card>
    );
  }

  const isActiveNode = timer.nodeId === nodeId && timer.active;
  const loading = props.loading || [];

  const handleTimer = () => {
    dispatch(
      setTimer({
        nodeId,
        startTime: new Date(),
        active: !isActiveNode,
        action: isActiveNode ? "stop" : "start",
      })
    );
  };

  return (
    <Link to={!groupingType && !recording ? `/activity/${_id}` : ""}>
      <Card recording={recording}>
        <Pill
          icon={
            !groupingType
              ? faWavePulse
              : groupingType === "nodeId"
              ? props.type === "task"
                ? faCheck
                : faPhone
              : faBriefcase
          }
          iconColor="#009eff"
        />
        {dateCreated && !groupingType ? (
          <Pill icon={faClock} responsive={false}>
            {dayjs(dateCreated).format("HH:mm")}
          </Pill>
        ) : (
          ""
        )}
        <Projects
          value={props.projectId || { value: null, label: "No project" }}
        />

        <Pill
          icon={copiedStatus ? faCheck : faCopy}
          iconColor="#009eff"
          fontSize={15}
          onClick={handleCopy}
        />
        <Pill expand>
          <span
            style={{
              textDecoration:
                props.status === "complete" ? "line-through" : "none",
            }}
          >
            {text}
          </span>
        </Pill>

        <Pill responsive={false} icon={faTimer}>
          {formattedDuration}
        </Pill>
        <Pill
          icon={
            loading.includes("timer")
              ? faSpinnerThird
              : isActiveNode
              ? faStop
              : faPlayCircle
          }
          onClick={handleTimer}
          tooltip={{ _id: nodeId, label: isActiveNode ? "Stop" : "Start" }}
          className={
            isActiveNode ? "tasks-active" : timer.active && "tasks-inactive"
          }
        >
          {isActiveNode ? "Stop" : "Start"}
        </Pill>
      </Card>
    </Link>
  );
};

export default ActivityCard;
