import { useState, useRef, useCallback } from "react";

const useAutoFocusInput = (autoHighlight) => {
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null);

  const showAndFocusInput = useCallback(() => {
    setShowInput(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.scrollLeft = 0;

        if (autoHighlight) {
          inputRef.current.select();
        }
      }
    }, 1);
  }, []);

  return { showInput, setShowInput, inputRef, showAndFocusInput };
};

export default useAutoFocusInput;
