import React from "react";
import "./navigation.css";
import { Pill } from "../pill/Pill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navigation = ({ text, active, onClick, count = 0, icon }) => {
  return (
    <div
      className={`navigation-container ${
        active && "navigation-container-active"
      }`}
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {text}
      {count > 0 && <div className="navigation-count">{count}</div>}
    </div>
  );
};

export { Navigation };
