import { combineReducers } from "redux";
import user from "../features/user/userSlice";
import activity from "../features/activity/activitySlice";
import node from "../features/node/nodeSlice";
import project from "../features/projects/projectSlice";
import toast from "../features/toast/toastSlice";

export default combineReducers({
  project,
  user,
  activity,
  node,
  toast,
});
