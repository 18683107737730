import React from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Pill, Projects } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { setTimer, selectTimer } from "../../user/userSlice";
import {
  faClock,
  faPhone,
  faPlayCircle,
  faStop,
  faStopwatch,
} from "@fortawesome/pro-light-svg-icons";
import { updateNode } from "../nodeSlice";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useFormatSeconds from "../../../hooks/useFormatSeconds";
dayjs.extend(duration);

const MeetingCard = ({ node, className, nodeStatus, urgent }) => {
  const dispatch = useDispatch();
  const timer = useSelector(selectTimer);
  const changedNodeId = nodeStatus.changeNodeId;
  const taskIsLoading = nodeStatus.taskIsLoading;

  const isActiveNode = timer.nodeId === node._id && timer.active;
  const time = useFormatSeconds(node.duration ? node.duration.total : 0);
  const handleProject = async (project) => {
    await dispatch(updateNode({ _id: node._id, projectId: project.value }));
  };

  const loading = node.loading || [];
  const params = useParams();
  const handleTimer = () => {
    dispatch(
      setTimer({
        nodeId: node._id,
        startTime: new Date(),
        active: !isActiveNode,
        action: isActiveNode ? "stop" : "start",
      })
    );
  };
  return (
    <Link to={`/tasks/${node._id}`}>
      <Card
        key={node._id}
        className={`${params.taskId === node._id ? "task-active" : ""}`}
        recording={isActiveNode}
        loading={taskIsLoading}
        changed={changedNodeId === node._id}
        selected={params.taskId === node._id}
      >
        <Pill
          icon={loading.includes("status") ? faSpinnerThird : faPhone}
          hoverIcon={loading.includes("status") ? faSpinnerThird : faPhone}
          className="taskspage-complete-icon"
          iconColor="#009eff"
          fontSize={15}
        />
        {loading.includes("projectId") ? (
          <Pill icon={faSpinnerThird} style={{ width: "130px" }} />
        ) : (
          <Projects value={node.projectId} onChange={handleProject} />
        )}
        <Pill icon={faClock}>{dayjs(node.dateTodo).format("HH:mm")}</Pill>
        <Pill icon={faStopwatch} width={65}>
          {node.duration ? time : "0.00h"}
        </Pill>
        <Pill
          expand
          textStyle={{
            textDecoration:
              node.status === "complete" ? "line-through" : "default",
          }}
        >
          {node.title}
        </Pill>{" "}
        <Pill
          icon={
            loading.includes("timer")
              ? faSpinnerThird
              : isActiveNode
              ? faStop
              : faPlayCircle
          }
          onClick={handleTimer}
          tooltip={{ _id: node._id, label: isActiveNode ? "Stop" : "Start" }}
          className={
            isActiveNode ? "tasks-active" : timer.active && "tasks-inactive"
          }
        >
          {isActiveNode ? "Stop" : "Start"}
        </Pill>
      </Card>
    </Link>
  );
};

export default MeetingCard;
