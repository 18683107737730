import React from "react";
import { Filters, Filter } from "../../../components/common";

const TaskFilters = ({ changeStatus, status }) => {
  const statusFilterOptions = [
    { value: "incomplete", label: "Not completed" },
    { value: "completed", label: "Complete" },
  ];

  return (
    <Filters>
      <Filter
        options={statusFilterOptions}
        value={statusFilterOptions.find(({ value }) => value === status)}
        onChange={changeStatus}
      />
    </Filters>
  );
};

export default TaskFilters;
