import React, { useState, useEffect } from "react";
import "./toast.css";
import {
  faCheck,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectToastStatus, clearToast } from "./toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { Pill } from "../../components/common";

const Toast = () => {
  const toasts = useSelector((state) => state.toast);
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();

  const icons = {
    error: faExclamationCircle,
    success: faCheck,
  };

  const handleReset = (_id) => {
    dispatch(clearToast(_id));
  };

  return (
    <div className="toasts-container">
      {toasts.map(({ status, message, _id }, key) => {
        if (key > 5) return null;
        setTimeout(() => handleReset(_id), 5000);
        return (
          <div className={`toast-container ${status}`} key={_id}>
            <div className="toast-label">
              <FontAwesomeIcon icon={icons[status]} />
            </div>
            <div className="toast-message">{message}</div>
            <Pill
              icon={faTimes}
              iconColor="#fff"
              onClick={() => handleReset(_id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Toast;
