import { createSlice, isRejected } from "@reduxjs/toolkit";
import { addNode, updateNode } from "../node/nodeSlice";

const initialState = [];

const createToast = ({ message, type = "success", title }) => ({
  status: type,
  message: (
    <>
      {message} {title ? " " : ""}
      <i>{title}</i>
    </>
  ),
  _id: crypto.randomUUID(),
});

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    clearToast: (state, action) => {
      const index = state.findIndex((toast) => toast._id === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNode.fulfilled, (state, action) => {
      state.unshift(
        createToast({
          message: `Added`,
          title: action.payload.node.title,
          type: "success",
        })
      );
    });
    builder.addCase(updateNode.fulfilled, (state, action) => {
      if (!action.payload.noToast)
        state.unshift(
          createToast({
            message: "Updated",
            title: action.payload.title,
            type: "success",
          })
        );
    });
    builder.addMatcher(isRejected(), (state, action) => {
      state.unshift(
        createToast({
          message: action.payload || "An unexpected error occurred",
          type: "error",
        })
      );
    });
  },
});

export const { clearToast } = toastSlice.actions;

export const selectToastStatus = (state) => state.status;

export default toastSlice.reducer;
