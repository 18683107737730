import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeDate } from "../../actions";
import "./index.css";
import { DateDropdown } from "../../components/common";
import Progress from "./Progress";
import Timer from "../user/Timer";
import { selectTimer } from "../user/userSlice";

const Header = () => {
  const timer = useSelector(selectTimer);

  return (
    <div className="header-container">
      <Timer timer={timer} />
    </div>
  );
};

export default Header;
