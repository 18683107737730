import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { addNode, getNode } from "../node/nodeSlice";
import { getTimer, setTimer } from "../user/userSlice";
import dayjs from "dayjs";
import _ from "lodash";

const initialState = {
  status: "idle",
  times: [],
  error: null,
  loadingNodes: [],
};

export const getActivity = createAsyncThunk(
  "/api/activity/retrieve/:date",
  async (date, thunkAPI) => {
    const { data } = await axios.get(
      `/api/activity/retrieve/${date || dayjs().format("YYYY-MM-DD")}`
    );
    return data;
  }
);

export const getActivityById = createAsyncThunk(
  "/api/activity/retrievebyid/:id",
  async (_id, thunkAPI) => {
    const { data } = await axios.get(`/api/activity/retrievebyid/${_id}`);
    return data;
  }
);

export const updateActivity = createAsyncThunk(
  "/api/activity/update/:id",
  async ({ _id, ...params }) => {
    const { data } = await axios.patch(`/api/activity/update/${_id}`, params);
    return data;
  }
);

export const deleteActivity = createAsyncThunk(
  "/api/activity/delete/:id",
  async ({ _id }) => {
    const { data } = await axios.delete(`/api/activity/delete/${_id}`);
    return data;
  }
);

const updateArray = ({ array, _id, payload }) => {
  const index = array.findIndex((value) => _id === value._id);

  if (index === -1) {
    // Equivalent to unshift`
    return [payload, ...array];
  }

  // Or modify
  return array.map((value) => {
    if (value._id !== _id) return value;
    return payload;
  });
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getActivity.fulfilled, (state, action) => {
      state.times = action.payload;
      state.status = "success";
    });
    builder.addCase(getActivity.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getActivity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getActivityById.fulfilled, (state, action) => {
      // If doesn't exist in state, just return it
      const index = state.times.findIndex(
        ({ _id }) => _id === action.payload._id
      );

      if (index === -1) {
        state.times.unshift(action.payload);
      } else {
        state[index] = action.payload;
      }

      state.status = "success";
    });
    builder.addCase(getActivityById.pending, (state, action) => {
      state.times = updateArray({
        array: state.times,
        _id: action.meta.arg,
        payload: { _id: action.meta.arg, loading: true },
      });
    });
    builder.addCase(getActivityById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(setTimer.fulfilled, (state, action) => {
      if (!action.payload.time) return;
      state.times.unshift(action.payload.time);
    });
    builder.addCase(addNode.fulfilled, (state, action) => {
      if (!action.payload.time) return;
      state.times.unshift(action.payload.time);
      state.status = "success";
    });
    builder.addCase(updateActivity.fulfilled, (state, action) => {
      const index = state.times.findIndex(
        (time) => time._id === action.payload.activity._id
      );
      if (index !== -1) {
        state.times[index] = action.payload.activity;
      }
      state.status = "success";
    });
    builder.addCase(updateActivity.pending, (state, action) => {
      state.status = "loading";
      const index = state.times.findIndex(
        (time) => time._id === action.meta.arg._id
      );

      if (index !== -1) {
        const loading = Object.keys(action.meta.arg).filter(
          (value) => value !== "_id"
        );
        state.times[index] = { ...state.times[index], loading: loading };
      }
    });
    builder.addCase(deleteActivity.pending, (state, action) => {
      state.status = "loading";
      const index = state.times.findIndex(
        (time) => time._id === action.meta.arg._id
      );

      if (index !== -1) {
        const loading = "delete";
        state.times[index] = { ...state.times[index], loading: loading };
      }
    });
    builder.addCase(deleteActivity.fulfilled, (state, action) => {
      state.times = state.times.filter(
        ({ _id }) => _id !== action.payload.activity._id
      );
      state.status = "success";
    });
    builder.addCase(getTimer.fulfilled, (state, action) => {
      if (action.payload.times) {
        state.times = _.uniqBy(
          [...state.times, ...action.payload.times],
          (value) => value._id
        );
      }
    });
    builder.addCase(getNode.pending, (state, action) => {
      state.loadingNodes.unshift(action.meta.arg._id);
    });
  },
});

export const activityStatus = (state) => state.activity.status;

const selectActivityBase = (state) => state.activity.times;

export const selectActivity = createSelector(
  [selectActivityBase, (nodeId) => nodeId],
  (times, _id) => times.find(({ nodeId }) => nodeId === _id)
);

export const selectActivityById = createSelector(
  [selectActivityBase, (state, _id) => _id],
  (times, _id) => times.find((time) => time._id === _id)
);

export default activitySlice.reducer;
