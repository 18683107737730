import { useState, useEffect } from "react";
import dayjs from "dayjs";

/**
 * A custom React hook that converts seconds to a formatted string "[x]h [y]m".
 * @param {number} seconds The number of seconds to convert.
 * @returns {string} The formatted time string.
 */
function useFormatSeconds(seconds) {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    if (seconds <= 0) return setFormattedTime("No time recorded");
    setFormattedTime(
      `${(
        Math.round(
          dayjs.duration(360 * Math.ceil(seconds / 360), "seconds").asHours() *
            10
        ) / 10
      ).toFixed(2)}h`
    );
  }, [seconds]);

  return formattedTime;
}

export default useFormatSeconds;
