import { useState, useEffect } from "react";

function useSecondsToTime(time) {
  const seconds = time.reduce((acc, item) => {
    // Check if the item has a 'duration' property and it's a number
    if (item.duration && typeof item.duration === "number") {
      return acc + item.duration;
    }
    return acc;
  }, 0);

  const [timeString, setTimeString] = useState("");

  useEffect(() => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    setTimeString(`${formattedHours}:${formattedMinutes}`);
  }, [seconds]);

  return timeString;
}

export default useSecondsToTime;
