import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const useTimeDifference = (time) => {
  // Current time
  const now = dayjs();

  // Parse the provided time for today
  const providedTime = dayjs()
    .hour(parseInt(time.split(":")[0]))
    .minute(parseInt(time.split(":")[1]));

  // Calculate difference in milliseconds
  let diff = providedTime.diff(now) * -1;

  // Use Day.js duration to handle the difference
  const diffDuration = dayjs.duration(diff);

  return diffDuration.format("HH:mm");
};

export default useTimeDifference;
