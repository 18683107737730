import { useState, useEffect } from "react";

// Hook to check if the screen width is less than 1280px
const useIsSmallScreen = (width = 1280) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < width);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= width);
    };

    // Set up event listener
    window.addEventListener("resize", handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [width]); // Empty array ensures that effect is only run on mount and unmount

  return isSmallScreen;
};

export default useIsSmallScreen;
