import React from "react";
import { EmptyState } from "../";
import "./stack.css";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Stack = ({
  children,
  title,
  expand = true,
  emptyState = {
    children: "There's nothing here",
    message: "No items to show",
  },
  showEmptyState = true,
  loading,
}) => {
  if (loading)
    return (
      <EmptyState message={"Loading"}>
        <FontAwesomeIcon icon={faSpinnerThird} fontSize={25} spin />
      </EmptyState>
    );

  if (!children.length) {
    if (showEmptyState)
      return (
        <EmptyState message={emptyState.message}>
          {emptyState.children}
        </EmptyState>
      );
    return;
  }

  return (
    <div className={`stack-container ${expand && "stack-container-expand"}`}>
      {title && <div className="stack-title">{title}</div>}
      {children}
    </div>
  );
};

export { Stack };
