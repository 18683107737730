import React from "react";
import "./newpage.css";
import { Pill } from "../";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

const NewPage = ({ description, children }) => {
  const navigate = useNavigate();
  return (
    <div className="newpage-container">
      <Pill
        icon={faLongArrowLeft}
        backgroundHover={false}
        fontSize={16}
        onClick={() => navigate(-1)}
      >
        <div className="newpage-back-text">Back</div>
      </Pill>
      <div className="newpage-description">{description}</div>
      <div className="newpage-children">{children}</div>
    </div>
  );
};

export { NewPage };
