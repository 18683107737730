import React from "react";
import useProjectColors from "../../../hooks/useProjectColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faSolidCircle } from "@fortawesome/pro-solid-svg-icons";

const Colors = ({ value = "blue", onChange }) => {
  const colors = useProjectColors();
  return Object.keys(colors).map((colorName) => {
    const selected = colorName === value;
    return (
      <FontAwesomeIcon
        key={colorName}
        onClick={() => onChange(colorName)}
        icon={selected ? faSolidCircle : faCircle}
        className={`projectnew-color ${selected && "selected"}`}
        style={{
          color: colors[colorName],
        }}
      />
    );
  });
};

export { Colors };
