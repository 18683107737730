import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  TextInput,
  Projects,
  TimePicker,
  Button,
  Pill,
} from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import {
  faPlus,
  faWavePulse,
  faSpinnerThird,
  faTimes,
  faStopwatch,
  faSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { addNode, nodeStatus } from "./nodeSlice";
import useIsTimeBeforeOrCurrent from "../../hooks/useIsTimeBeforeOrCurrent";
import useTimeDifference from "../../hooks/useTimeDifference";
import useDetectProject from "../../hooks/useDetectProject";
import dayjs from "dayjs";
import useKeyboardActions from "../../hooks/useKeyboardActions";
import "./newnodecard.css";
import useClickOutside from "../../hooks/useClickOutside";
import useTimeToSeconds from "../../hooks/useTimeToSeconds";
import { setTimer } from "../user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import useScreenSize from "../../hooks/useScreenSize";

const NewNodeCard = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const { addNodeLoading } = useSelector(nodeStatus);

  // Define input values
  const [title, setTitle] = useState("");
  const [project, setProject] = useState("");
  const [startTime, setStartTime] = useState(dayjs().format("HH:mm"));
  const [duration, setDuration] = useState();
  const [addActivity, setAddActivity] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [status, setStatus] = useState("active");

  const screenSize = useScreenSize();

  // Setup refs for each field to track events
  const textInputRef = useRef();
  const durationSeconds = useTimeToSeconds(duration);

  useEffect(() => {
    setStartTime(dayjs().format("HH:mm"));
  }, [addActivity]);

  // Function to reset the text box
  const handleReset = () => {
    setTitle("");
    setProject("");

    setStartTime(dayjs().format("HH:mm"));
    setDuration();
    setAddActivity(false);
    setStartTimer(false);
    setStatus("active");
  };

  // Function to handle submit
  const handleSubmit = async (nodeType) => {
    const splitTime = startTime.split(":");

    const startDate = dayjs()
      .startOf("day")
      .add(splitTime[0], "hour")
      .add(splitTime[1], "minute");

    const endTime = startDate.add(durationSeconds, "second");
    const timeDiff = dayjs(endTime).diff(dayjs(), "minute");

    // Continue the timer if the resulting time is less than a minute apart
    const overlap = startTimer ? timeDiff <= 1 && timeDiff >= -1 : false;
    const newTask = await dispatch(
      addNode({
        title,
        type: nodeType,
        projectId: project.value,
        status,
        duration: {
          total: overlap ? 0 : durationSeconds,
          [dayjs().format("YYYY-MM-DD")]: overlap ? 0 : durationSeconds,
        },
      })
    );
    if (startTimer) {
      dispatch(
        setTimer({
          nodeId: newTask.payload.node._id,
          startTime: overlap ? startDate : new Date(),
          active: true,
          action: "start",
        })
      );
    }
    handleReset();
  };

  // Setup keyboard actions
  useKeyboardActions(textInputRef, () => handleSubmit(type), handleReset);

  // Hook to compare time differences
  const isTimeBeforeOrCurrent = useIsTimeBeforeOrCurrent(startTime);
  const timeDifference = useTimeDifference(startTime);

  // Adjust the duration if time is changed
  useEffect(() => {
    if (!isTimeBeforeOrCurrent) {
      setStartTime(dayjs().format("HH:mm"));
    }
    setDuration(timeDifference);
  }, [startTime]);

  // Detect project hook
  const detectedProject = useDetectProject(title);

  useEffect(() => {
    if (!title) {
      setProject("");
    }

    if (detectedProject && title) {
      setProject({
        label: detectedProject.title,
        value: detectedProject._id,
        color: detectedProject.color,
      });

      const needle = `/${detectedProject.title.toLowerCase()}`;
      if (title.toLowerCase().includes(`${needle} `)) {
        setTitle(title.replace(`${needle} `, ""));
      } else if (title.toLowerCase().endsWith(` ${needle}`)) {
        setTitle(title.replace(` ${needle}`, ""));
      }
    }
  }, [detectedProject, title]);

  const [type, setType] = useState("task");

  useEffect(() => {
    const meetingWords = [
      "call",
      "meeting",
      "discussion",
      "catch-up",
      "meet",
      "weekly",
      "chat",
      "catchup",
      "catch-up",
    ];
    if (meetingWords.some((word) => _(title.toLowerCase()).includes(word))) {
      setType("meeting");
      setStartTimer(true);
    } else setType("task");
  }, [title]);

  const ref = useRef();

  useClickOutside({
    ref,
    focusState: visible,
    onSubmit: () => setVisible(false),
    onBlur: () => setVisible(false),
  });

  const Options = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Projects
            key={project.value}
            value={project.value}
            responsive={false}
            onChange={(project) => setProject(project)}
          />
          {!addActivity ? (
            <Pill
              fontSize={14}
              tooltip={{ _id: "newnodeactivity", label: "Activity " }}
              icon={faWavePulse}
              responsive={false}
              onClick={() => setAddActivity(!addActivity)}
            >
              Activity
            </Pill>
          ) : (
            <div className="newtaskcard-activity">
              <TimePicker
                type="time"
                value={startTime || dayjs().format("HH:mm")}
                onChange={(time) => setStartTime(time)}
              />
              <TimePicker
                type="duration"
                value={duration}
                onChange={setDuration}
              />
              <Pill
                icon={faTimes}
                onClick={() => setAddActivity(false)}
                responsive={false}
              />
            </div>
          )}
          <Pill
            fontSize={14}
            responsive={false}
            icon={faStopwatch}
            tooltip={{ _id: "newnodetimer", label: "Start timer" }}
            onClick={() => setStartTimer(!startTimer)}
            iconColor={startTimer && "#009eff"}
            className={"timer-pill"}
            slash={!startTimer}
          >
            <span style={{ color: startTimer && "#009eff" }}>Timer</span>
          </Pill>
          {type === "task" && (
            <Pill
              fontSize={14}
              responsive={false}
              icon={faCheck}
              tooltip={{ _id: "newnodecompleted", label: "Mark as completed" }}
              iconColor={status === "complete" && "#009eff"}
              onClick={() =>
                setStatus(status === "complete" ? "active" : "complete")
              }
            >
              {" "}
              <span style={{ color: status === "complete" && "#009eff" }}>
                Complete
              </span>
            </Pill>
          )}{" "}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={`newnodecard-container ${
        visible
          ? "newnodecard-container-visible"
          : "newnodecard-container-hidden"
      }`}
    >
      <Card hover={false} className="last newcard" loading={addNodeLoading}>
        {addNodeLoading ? (
          <Pill
            icon={faSpinnerThird}
            spin={true}
            fontSize={16}
            className="newtaskcard-loading-indicator"
          ></Pill>
        ) : (
          <TextInput
            placeholder="Start typing your new task here..."
            type="inline"
            ref={textInputRef}
            input
            value={title}
            onChange={(text) => setTitle(text)}
          />
        )}
        <>
          <Button
            disabled={!title}
            onClick={() => handleSubmit(type)}
            icon={faPlus}
            options={[
              {
                label: `Add ${type === "meeting" ? "Task" : "Meeting"}`,
                icon: faPlus,
                onClick: () => {
                  handleSubmit(type === "meeting" ? "task" : "meeting");
                },
              },
            ]}
          >
            {type === "meeting" ? "Meeting" : "Task"}
          </Button>
        </>
      </Card>
      <Card>
        <Options />
      </Card>
    </div>
  );
};

export default NewNodeCard;
