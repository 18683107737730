import React from "react";
import { Page, Title, Stack, Card, Button } from "../../components/common";
import { faBriefcase } from "@fortawesome/pro-regular-svg-icons";
import ProjectCard from "./ProjectCard";
import { Link } from "react-router-dom";
import { selectProjects } from "./projectSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectDrawer from "./ProjectDrawer";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const NewProjectButton = () => {
  return (
    <Link to={"/project/new"}>
      <Button onClick={() => {}} icon={faPlus}>
        New Project
      </Button>
    </Link>
  );
};

const ProjectsPage = () => {
  const projects = [...useSelector(selectProjects)].sort((a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });

  const { projectId } = useParams();

  return (
    <Page
      drawer={<ProjectDrawer projectId={projectId} key={projectId} />}
      drawerIsVisible={projectId}
      source="/projects"
    >
      <Title icon={faBriefcase} button={<NewProjectButton />}>
        Projects
      </Title>
      <Stack
        emptyState={{
          message: "No projects yet",
          children: (
            <>
              {" "}
              <Link to={"/project/new"}>Click here</Link> to create your first
              project{" "}
            </>
          ),
        }}
      >
        {projects.map((project) => (
          <ProjectCard
            key={project._id}
            _id={project._id}
            title={project.title}
            color={project.color}
          />
        ))}
      </Stack>
    </Page>
  );
};

export default ProjectsPage;
