import React from "react";
import { Card, Pill } from "../../components/common";
import { faCircle, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import useProjectColors from "../../hooks/useProjectColors";
import "./projectcard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ProjectCard = ({ _id, title, color = "blue" }) => {
  const iconColor = useProjectColors()[color];

  return (
    <Link to={`/projects/${_id}`}>
      <Card className="project-card-container">
        <Pill icon={faCircle} iconColor={iconColor} responsive={false}>
          {title}
        </Pill>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="project-card-chevron"
        />
      </Card>
    </Link>
  );
};

export default ProjectCard;
