import React from "react";
import MenuItem from "./MenuItem";
import "./index.css";

const Menu = ({ items, link }) => {
  return (
    <div className="menu-container">
      <div className="menu-container">
        {items.map(({ icon, text, link, onClick, isDefault = false }) => (
          <MenuItem
            key={text}
            icon={icon}
            text={text}
            link={link}
            onClick={onClick}
            isDefault={isDefault}
          />
        ))}
      </div>
    </div>
  );
};

export default Menu;
