import React, { useState } from "react";
import {
  Drawer,
  Node,
  Pill,
  TimePicker,
  DateDropdown,
  Colors,
  TextInput,
  NodeSection,
  Stack,
  Button,
} from "../../components/common";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import { selectProject, updateProject, deleteProject } from "./projectSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  faTag,
  faTrashAlt,
  faPlus,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SettingsDrawerNodes = ({ project, onChange, handleDelete }) => {
  const [newKeyword, setNewKeyword] = useState();
  const [newKeywordVisible, setNewKeywordVisible] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.project.status) === "loading";

  return (
    <>
      <NodeSection>
        <Node title="Color">
          <Colors
            value={project.color}
            onChange={(color) => onChange({ color })}
          />
        </Node>
      </NodeSection>
      <NodeSection>
        <Node
          title="Keywords"
          info="Use these words to assign project to task/meeting"
          type="list"
          vertical
        >
          {project.keywords.map((keyword) => {
            if (keyword === null) return;
            return (
              <Pill
                key={keyword}
                icon={faTag}
                hoverIcon={faTrashAlt}
                onClick={() =>
                  onChange({
                    keywords: project.keywords.filter(
                      (value) => value !== keyword
                    ),
                  })
                }
              >
                {keyword}
              </Pill>
            );
          })}
          <TextInput
            placeholder={"Type new keyword"}
            icon={faPlus}
            value={newKeyword}
            onChange={setNewKeyword}
            onSubmit={() => {
              onChange({ keywords: [...project.keywords, newKeyword] });
              setNewKeyword();
              setNewKeywordVisible(false);
            }}
          />
        </Node>
      </NodeSection>
      <NodeSection>
        <Button type="danger" onClick={handleDelete}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          ) : (
            "Delete Project"
          )}
        </Button>
      </NodeSection>
    </>
  );
};

const ActivityDrawerNodes = () => {
  return (
    <>
      <NodeSection></NodeSection>
    </>
  );
};

const ActivityDrawer = ({ projectId }) => {
  const [drawerNodes, setDrawerNodes] = useState("settings");
  const navigate = useNavigate();
  const project = useSelector((state) => selectProject(state, projectId));
  const dispatch = useDispatch();

  const handleUpdate = (obj) =>
    dispatch(updateProject({ ...obj, _id: projectId }));

  const handleDelete = async () => {
    await dispatch(deleteProject({ _id: project._id }));
    navigate("/projects");
  };

  if (!project) return;

  return (
    <Drawer
      visible={!!projectId}
      _id={projectId}
      title={project ? project.title : ""}
      onChangeTitle={(title) => handleUpdate({ title })}
      onClose={() => navigate("/projects")}
      navigation={[
        {
          text: "Settings",
          onClick: () => setDrawerNodes("settings"),
          active: drawerNodes === "settings",
        },
        {
          text: "Activity",
          onClick: () => setDrawerNodes("activity"),
          active: drawerNodes === "activity",
        },
      ]}
    >
      {!!projectId && drawerNodes === "settings" ? (
        <SettingsDrawerNodes
          project={project}
          onChange={handleUpdate}
          handleDelete={handleDelete}
        />
      ) : (
        <ActivityDrawerNodes />
      )}
    </Drawer>
  );
};

export default ActivityDrawer;
