import { useEffect } from "react";

// Hook to detect Enter and Escape key presses on a specific input field
const useKeyboardActions = (inputRef, onEnter, onEscape) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === "Enter" &&
        inputRef.current &&
        inputRef.current === document.activeElement
      ) {
        onEnter && onEnter();
      } else if (
        event.key === "Escape" &&
        inputRef.current &&
        inputRef.current === document.activeElement
      ) {
        onEscape && onEscape();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputRef, onEnter, onEscape]);
};

export default useKeyboardActions;
