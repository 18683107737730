import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";

import { setTimer } from "../user/userSlice";
import {
  deleteActivity,
  getActivity,
  updateActivity,
} from "../activity/activitySlice";

const initialState = {
  status: "idle",
  nodes: [],
  addNodeLoading: false,
  error: null,
};

export const getNodes = createAsyncThunk("/api/nodes/retrieve", async () => {
  const { data } = await axios.get(`/api/nodes/retrieve`);
  return data;
});

export const getNode = createAsyncThunk(
  "/api/nodes/retrieve/:id",
  async (params) => {
    const { _id } = params;
    if (!_id) return;
    const { data } = await axios.get(`/api/nodes/retrieve/${_id}`);
    return data;
  }
);
export const addNode = createAsyncThunk(
  "/api/nodes/add",
  async (
    { title, type, status, time, assignee, dateTodo, projectId, duration },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post("/api/nodes/add", {
        title,
        status,
        type,
        time,
        assignee,
        dateTodo,
        duration,
        projectId,
      });
      return data;
    } catch (error) {
      if (error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue("Item could not be created");
    }
  }
);

export const updateNode = createAsyncThunk(
  "/api/nodes/update",
  async ({ _id, ...params }) => {
    const { data } = await axios.patch(`/api/nodes/update/${_id}`, params);
    return { ...data, noToast: !!params.noToast };
  }
);
const updateArray = ({ array, _id, payload, loading }) => {
  const index = array.findIndex((value) => _id === value._id);

  if (index === -1) {
    // Equivalent to unshift`
    return [payload, ...array];
  }

  // Or modify
  return array.map((value) => {
    if (value._id !== _id) return value;
    // If we have a payload, replace in entirity
    if (payload) return payload;
    // Otherwise add in the loading array
    return {
      ...value,
      loading: Object.keys(loading).filter((value) => value !== "_id"),
    };
  });
};
export const nodeSlice = createSlice({
  name: "node",
  initialState,
  reducers: {
    resetChangedNodeId(state) {
      state.changedNodeId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNodes.fulfilled, (state, action) => {
      state.nodes = action.payload;
      state.status = "success";
    });
    builder.addCase(getNodes.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getNodes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getNode.fulfilled, (state, action) => {
      state.status = "success";
      if (!action.payload) return;

      state.nodes = updateArray({
        array: state.nodes,
        _id: action.payload._id,
        payload: action.payload,
      });
    });
    builder.addCase(getNode.pending, (state, action) => {
      state.nodes = updateArray({
        array: state.nodes,
        _id: action.meta.arg._id,
        payload: { _id: action.meta.arg._id, loading: [] },
      });
    });
    builder.addCase(getNode.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(addNode.fulfilled, (state, action) => {
      state.nodes.unshift(action.payload.node);
      state.addNodeLoading = false;
    });
    builder.addCase(addNode.pending, (state) => {
      state.addNodeLoading = true;
    });
    builder.addCase(addNode.rejected, (state, action) => {
      state.addNodeLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateNode.fulfilled, (state, action) => {
      state.nodes = updateArray({
        array: state.nodes,
        _id: action.payload._id,
        payload: action.payload,
      });
    });
    builder.addCase(updateNode.pending, (state, action) => {
      state.status = "loading";

      state.nodes = updateArray({
        array: state.nodes,
        _id: action.meta.arg._id,
        loading: action.meta.arg,
      });
    });
    builder.addCase(updateNode.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload ? action.payload : null;
    });
    builder.addCase(setTimer.fulfilled, (state, action) => {
      console.log(action.meta.arg.action);
      if (action.meta.arg.action === "stop") {
        state.nodes = updateArray({
          array: state.nodes,
          _id: action.meta.arg.nodeId,
          payload: action.payload.node,
        });
      }
      if (
        action.meta.arg.action === "start" ||
        action.meta.arg.action === "update"
      ) {
        state.nodes = updateArray({
          array: state.nodes,
          _id: action.meta.arg.nodeId,
          loading: [],
        });
      }
    });
    builder.addCase(setTimer.pending, (state, action) => {
      state.nodes = updateArray({
        array: state.nodes,
        _id: action.meta.arg.nodeId,
        loading: { timer: true },
      });
    });
    builder.addCase(setTimer.rejected, (state, action) => {
      const index = state.nodes.findIndex(
        (node) => node._id === action.meta.arg.nodeId
      );
      if (index !== -1) {
        state.nodes[index] = { ...state.nodes[index], loading: null };
      }
    });

    builder.addCase(updateActivity.fulfilled, (state, action) => {
      console.log(action.payload);
      state.nodes = updateArray({
        array: state.nodes,
        _id: action.payload.node._id,
        payload: action.payload.node,
      });
    });

    builder.addCase(deleteActivity.fulfilled, (state, action) => {
      state.nodes = updateArray({
        array: state.nodes,
        _id: action.payload.node._id,
        payload: action.payload.node,
      });
    });
  },
});

const selectNodeStatus = (state) => state.node.status;
const selectAddNodeLoading = (state) => state.node.addNodeLoading;

const selectAllNodeStatus = createSelector(
  [selectNodeStatus, selectAddNodeLoading],
  (nodeStatus, addNodeLoading) => ({
    status: nodeStatus,
    addNodeLoading: addNodeLoading,
  })
);

export const nodeStatus = (state) => selectAllNodeStatus(state);

const selectNodesBase = (state) => {
  return state.node.nodes;
};

export const selectNodes = createSelector([selectNodesBase], (nodes) => {
  return nodes.filter((node) => node.status === "active");
});

export const selectPinnedNodes = createSelector([selectNodesBase], (nodes) => {
  return nodes.filter((node) => node.status === "pinned");
});

export const selectCompletedTasks = createSelector([selectNodesBase], (nodes) =>
  nodes.filter((node) => node.status === "complete")
);

export const selectNode = createSelector(
  [selectNodesBase, (state, nodeId) => nodeId],
  (nodes, nodeId) => nodes.find(({ _id }) => _id === nodeId)
);

export const selectNodeIndex = (nodes, nodeId) =>
  nodes.findIndex(({ _id }) => _id === nodeId);

export const selectNotes = createSelector([selectNodesBase], (nodes) => {
  return nodes.filter((node) => node.notes);
});

export const { resetChangedNodeId } = nodeSlice.actions;

export default nodeSlice.reducer;
