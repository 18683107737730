import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./title.css";

const Title = ({ children, icon, button }) => {
  return (
    <div className="title-container">
      <div className="title-text-container">
        <FontAwesomeIcon icon={icon} className="title-icon" />
        <div className="title-text">{children}</div>
      </div>
      {button}
    </div>
  );
};

export { Title };
