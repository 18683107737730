import React, { useEffect, useState } from "react";
import "./drawer.css";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawer } from "../../../actions";
import { TextInput } from "../textinput/TextInput";
import { Navigation } from "../navigation/Navigation";
import { Pill } from "../pill/Pill";
import { Close } from "../button/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

const Drawer = ({
  title = "Drawer title",
  onChangeTitle,
  navigation,
  children = false,
  visible,
  onClose,
  loading,
}) => {
  const dispatch = useDispatch();

  const [tempTitle, setTempTitle] = useState(title);

  useEffect(() => {
    setTempTitle(title);
  }, [title]);

  const handleChangeTitle = () => {
    if (onChangeTitle && tempTitle !== title) onChangeTitle(tempTitle);
  };

  const handleReset = () => {
    setTempTitle(title);
  };

  if (loading) {
    return (
      <div
        className={`drawer-container ${
          visible ? "drawer-visible" : "drawer-hidden"
        } drawer-loading`}
      >
        <Pill icon={faSpinnerThird} fontSize="30px" />
      </div>
    );
  }

  return (
    <div
      className={`drawer-container ${
        visible ? "drawer-visible" : "drawer-hidden"
      }`}
    >
      <div className="drawer-title">
        <TextInput
          value={tempTitle || title}
          onChange={onChangeTitle ? (text) => setTempTitle(text) : false}
          onBlur={handleReset}
          onSubmit={handleChangeTitle}
          autoHighlight={false}
        />
        <Close onClick={onClose} />
      </div>
      <div className="drawer-menu">
        {navigation.map(({ text, active, onClick }) => (
          <Navigation
            key={text}
            text={text}
            active={active}
            onClick={onClick}
          />
        ))}
      </div>

      <div className="drawer-nodes">{children}</div>
    </div>
  );
};

export { Drawer };
