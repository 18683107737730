import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactTimePicker from "react-time-picker";
import useTimeIncrement from "../../../hooks/useTimeIncrement";
import {
  faTimer,
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Pill } from "../"; // Import Pill component from your components
import "./timepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock } from "@fortawesome/pro-light-svg-icons";
import useClickOutside from "../../../hooks/useClickOutside";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const TimePicker = ({
  type = "time",
  value,
  onChange = () => {},
  timer = false,
  editable = true,
  now = false,
  label,
}) => {
  const [showPicker, setShowPicker] = useState(false); // State to manage the visibility of the time picker
  const [tempValue, setTempValue] = useState(value); // Temporary state to hold the value before confirmation

  // Function to handle closing of the picker and setting the time
  const onClockClose = () => {
    const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const splitTime = tempValue.split(":");
    const hours = Number(splitTime[0]);
    const minutes = Number(splitTime[1]);

    if (!regex.test(tempValue) || hours > 23 || minutes > 59) {
      setTempValue(value);
      return setShowPicker(false);
    }
    onChange(tempValue);
    setShowPicker(false);
  };

  const handleReset = () => {
    setTempValue(value);
    setShowPicker(false);
  };

  // Custom hook to handle enter key press for accessibility
  useClickOutside({
    useRef: false,
    focusState: showPicker,
    onSubmit: onClockClose,
    onBlur: handleReset,
  });

  // Side effect to focus and select the input field content when the picker is shown
  useEffect(() => {
    if (!showPicker) return;

    // Set a timeout to focus on the input field after it becomes visible
    const timer = setTimeout(() => {
      // Selector for the hour input in the time picker
      const inputSelector =
        type === "time" ||
        (value && value.includes(":") ? value.split(":")[0] : value) > 0
          ? ".react-time-picker__inputGroup__hour"
          : ".react-time-picker__inputGroup__minute";
      const inputElement = document.querySelector(inputSelector);

      if (inputElement) {
        inputElement.focus();
        setTimeout(() => {
          inputElement.select(); // Select the content to easily modify it
        }, 10);
      }
    }, 10);

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timer);
  }, [showPicker, type]);

  const processedValue = !now
    ? value
    : value.split(":")[1] === dayjs().minute().toFixed()
    ? "Now"
    : value;

  // Render the time picker or the pill based on the `showPicker` state
  return showPicker && editable ? (
    <div className="timepicker-container">
      <FontAwesomeIcon
        icon={type === "time" ? faClock : faTimer}
        className="timepicker-icon"
      />
      {type === "time" && (
        <ReactTimePicker
          value={value}
          disableClock={true}
          onClockClose={onClockClose}
          onChange={(value) => {
            setTempValue(value);
          }}
          locale="en-gb"
          autofocus
          clearIcon={null}
          format="HH:mm"
          minTime="00:00:00"
          maxTime="23:59:59"
        />
      )}
      {type === "duration" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReactTimePicker
            value={value || "00:00"}
            disableClock={true}
            onClockClose={onClockClose}
            onChange={setTempValue}
            locale="en-gb"
            autofocus
            clearIcon={null}
            format="HH:mm"
          />
        </div>
      )}
    </div>
  ) : (
    <Pill
      icon={type === "time" ? faClock : faTimer}
      responsive={false}
      expand
      textStyle={{ width: 40 }}
      onClick={() => setShowPicker(true)} // Toggle the time picker visibility
    >
      {processedValue || "00:00"}
    </Pill>
  );
};

export { TimePicker };
