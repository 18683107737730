import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Pill, TimePicker } from "../../components/common";
import "./timer.css";
import {
  faArrowRight,
  faArrowUpRightFromSquare,
  faTimer,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPlayPause,
  faSave,
} from "@fortawesome/pro-solid-svg-icons";
import { faStop, faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import useTimeIncrement from "../../hooks/useTimeIncrement";
import usePageVisibility from "../../hooks/usePageVisibility";
import { selectNode } from "../node/nodeSlice";
import { setTimer } from "./userSlice";
import { Link } from "react-router-dom";

const convertTimeToDate = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);
  // Create a dayjs object with the current date and set the hours and minutes
  const dateWithTime = dayjs()
    .hour(hours)
    .minute(minutes)
    .second(0)
    .millisecond(0);

  // Convert the dayjs object to a JavaScript Date object
  return dateWithTime.toDate();
};

const Timer = ({ timer }) => {
  const dispatch = useDispatch();

  const task = useSelector((state) => selectNode(state, timer.nodeId));

  const time = dayjs(timer.startTime).format("HH:mm");
  const maxTime = 3600 * 2;

  const timeIncrement = useTimeIncrement(true, timer.active, maxTime);
  const timeIncrementSeconds = useTimeIncrement(false, timer.active, maxTime);

  const onTimeChange = ({ value, field }) => {
    // Make sure the proposed time isn't after the current time
    const splitTime = value.split(":");
    const newTime = dayjs(timer.startTime)
      .set("hour", splitTime[0])
      .set("minute", splitTime[1]);
    const currentTime = dayjs();

    // If proposed time is after current time, return
    if (newTime.isAfter(currentTime)) return;

    if (field === "start") {
      // Otherwise update the timer
      return dispatch(
        setTimer({
          nodeId: task._id,
          active: true,
          startTime: convertTimeToDate(value),
          action: "update",
        })
      );
    }

    if (field === "end") {
      const splitTime = value.split(":");

      const startTime = dayjs(timer.startTime);
      const endTime = startTime
        .set("hour", splitTime[0])
        .set("minute", splitTime[1]);
      return dispatch(
        setTimer({
          nodeId: task._id,
          active: true,
          action: "stop",
          duration: endTime.diff(startTime, "second"),
        })
      );
    }
  };

  if (!timer.active) return;

  return (
    <div
      className={`timer-container ${
        timer.startTime ? "timer-container-full" : "timer-container-hidden"
      }`}
    >
      <div className="timer-activity">
        <Pill
          icon={
            timeIncrementSeconds < maxTime ? faCircle : faExclamationTriangle
          }
          iconColor="#BF2525"
          textStyle={{ color: timeIncrementSeconds < maxTime ? "" : "#BF2525" }}
          maxWidth={250}
          responsive={false}
        >
          {task && task.title}
        </Pill>
      </div>
      <div className="timer-times">
        <TimePicker
          type="time"
          value={time}
          onChange={(value) => onTimeChange({ value, field: "start" })}
        />
        <Pill icon={faArrowRight} color="grey" style={{ fontSize: 12 }} />
        <TimePicker
          type="time"
          now
          onChange={(value) => onTimeChange({ value, field: "end" })}
          value={dayjs(timer.startTime)
            .add(timeIncrementSeconds, "seconds")
            .format("HH:mm")}
        />
        <Pill icon={faTimer} responsive={false}>
          {timeIncrement}
        </Pill>
      </div>
      <Pill
        responsive
        icon={faPlayPause}
        color="blue"
        onClick={() =>
          dispatch(
            setTimer({
              nodeId: task._id,
              active: false,
              startTime: new Date(),
              action: "stop",
              duration: timeIncrementSeconds,
            })
          )
        }
      >
        Save
      </Pill>
      <Link to={`/tasks/${task ? task._id : ""}`}>
        <Pill icon={faArrowUpRightFromSquare} color="blue" responsive>
          Open
        </Pill>
      </Link>
    </div>
  );
};

export default Timer;
