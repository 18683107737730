const useColorToRgba = (alpha = 1) => {
  const hexToRgba = (hex, alpha) => {
    // Convert hex to RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    // Return RGBA color with provided alpha
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const colorMapRgba = {
    red: hexToRgba("#CE1B1B", alpha),
    green: hexToRgba("#00B233", alpha),
    blue: hexToRgba("#009eff", alpha),
    pink: hexToRgba("#FF1493", alpha),
    purple: hexToRgba("#8008FF", alpha),
    orange: hexToRgba("#FF6600", alpha),
    grey: hexToRgba("#666666", alpha),
  };

  return colorMapRgba;
};

export default useColorToRgba;
