import React from "react";
import { useSelector } from "react-redux";
import "./progress.css";
import { selectNodes } from "../node/nodeSlice";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const Progress = () => {
  const tasks = useSelector(selectNodes());

  // Function to loop through all tasks and add up time from today
  const allTime = tasks.reduce((acc, task) => {
    return [
      ...acc,
      ...task.time.filter((time) => {
        if (dayjs(time.dateCreated).isSame(dayjs(), "day")) return time;
        return false;
      }),
    ];
  }, []);

  // Now add up all the duration in the time
  const seconds = allTime.reduce((acc, time) => {
    return acc + time.duration;
  }, 0);

  const hours = dayjs.duration(seconds, "seconds").asHours();

  const left = 8 - Math.floor(hours);
  return (
    <div className="progress-container">
      <div
        className={`progress ${hours > 8 ? "full" : ""}`}
        style={{ width: `${(hours / 8) * 100}%` }}
      >
        <div className="progress-text">
          {Math.floor(hours * 10) / 10}h total
        </div>
      </div>
    </div>
  );
};

export default Progress;
