import React from "react";
import "./filter.css";
import { Dropdown } from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquirrel } from "@fortawesome/pro-regular-svg-icons";
import { components } from "react-select";

const FilterValue = ({ value, icon }) => {
  return (
    <div className="filter-value">
      {icon ? <FontAwesomeIcon icon={icon} /> : ""}
      <span>{value}</span>
    </div>
  );
};

const Filter = ({ options = [], value, placeholder, onChange }) => {
  const processedOptions = options.map(({ value, label, icon }) => ({
    value,
    label: <FilterValue key={value} value={label} icon={icon} />,
  }));

  const style = {
    singleValue: (provided, state) => ({
      ...provided,
      color: "#666",
      fontSize: 15,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 15,
      fontWeight: "normal",
      color: state.isFocused ? "#000" : "#939393",
      cursor: "pointer",
      backgroundColor: "#ffffff",
      fontFamily: "Lato, sans-serif",
      ":active": {},
      padding: "10px 10px",
    }),

    control: (provided, state) => ({
      ...provided,
      fontFamily: "Lato, sans-serif",
      outline: "none",
      borderColor: "transparent",
      boxShadow: "none",
      borderRadius: 15,
      border: "0px",
      backgroundColor: "transparent",
      color: "#939393",
      fontSize: 15,
      padding: "0px 10px 0px 0px",
      cursor: "pointer",
    }),
    valueContainer: (provided) => ({
      ...provided,
      minWidth: 120,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 15,
      display: "flex",
      gap: 10,
      alignItems: "center",
    }),
  };
  return (
    <Dropdown
      options={processedOptions}
      style={style}
      value={value}
      isSearchable={false}
      placeholder={placeholder}
      isClearable={true}
      onChange={(event) =>
        event === null ? onChange(false) : onChange(event.value)
      }
    />
  );
};

export { Filter };
