import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  projects: [],
  error: null,
};

const findProjectIndex = (state, newProject) => {
  return state.projects.findIndex((project) => project._id === newProject._id);
};

export const getProjects = createAsyncThunk(
  "/api/projects/retrieve",
  async () => {
    const { data } = await axios.get("/api/projects/retrieve");
    return data;
  }
);

export const addProject = createAsyncThunk(
  "/api/projects/add",
  async ({ title, keywords, color }) => {
    const { data } = await axios.post("/api/projects/add", {
      title,
      keywords,
      color,
    });
    return data;
  }
);

export const updateProject = createAsyncThunk(
  "/api/projects/update",
  async ({ _id, ...obj }) => {
    const { data } = await axios.patch("/api/projects/update", {
      _id,
      ...obj,
    });
    return data;
  }
);

export const deleteProject = createAsyncThunk(
  "/api/projects/delete",
  async ({ _id }) => {
    const { data } = await axios.patch("/api/projects/update", {
      _id,
      status: false,
    });
    return data;
  }
);

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.status = "success";
    });
    builder.addCase(getProjects.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getProjects.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(addProject.fulfilled, (state, action) => {
      state.projects.unshift(action.payload);
      state.status = "success";
    });
    builder.addCase(addProject.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(addProject.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      const index = findProjectIndex(state, action.payload);
      state.projects[index] = action.payload;
      state.status = "success";
    });
    builder.addCase(updateProject.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.projects = state.projects.filter(
        (project) => project._id !== action.payload._id
      );
      state.status = "success";
    });
    builder.addCase(deleteProject.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteProject.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export const projectStatus = (state) => state.project.status;
export const selectProjects = (state) => state.project.projects;
export const selectProject = (state, _id) =>
  state.project.projects.find((project) => project._id === _id);

export default projectSlice.reducer;
