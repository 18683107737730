import React from "react";
import { EmptyState } from "../emptystate/EmptyState";
import "./grid.css";

const Grid = ({
  children = [],
  showEmptyState = true,
  emptyState = {
    children: "There's nothing here",
    message: "No items to show",
  },
}) => {
  if (!children && !children.length) {
    if (showEmptyState)
      return (
        <EmptyState message={emptyState.message}>
          {emptyState.children}
        </EmptyState>
      );
    return;
  }
  return <div className="grid-container">{children}</div>;
};

export { Grid };
